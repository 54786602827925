import React, {
  useCallback,
  useRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/Auth2';
import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { AnimationContainer } from './styles';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';
import { useEvent } from '../../hooks/EventContext';
import ContainerButton from '../../components/ContainerButton';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Languages from '../../components/Languages';
import whatsappIcon from '../../assets/images/whatsapp.svg';
import { useParams } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';
import { asEnumerable } from 'linq-es2015';
import { LoginUtils } from '../../utils/Login';

interface SignInFormData {
  email: string;
  password: string;
  pagestart: string;
}

const SignIn: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  //localStorage.clear();
  const { event } = useParams<{ event?: string }>();
  const formRef = useRef<FormHandles>(null);

  const { signIn, signOut, getUser } = useAuth();
  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const user = getUser();

  const { addToast } = useToast();
  const history = useHistory();
  const eventContext = useEvent();
  const [loading, setLoading] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState<any>();
  const errorsAny: any = errors;
  const [isVerified, setisVerified] = useState(false);
  const [forceRecaptcha, setForceRecaptcha] = useState(false);
  const recaptchaRef = useRef<Recaptcha>(null);
  // if (user && user.id) {
  //   history.replace(`/${props.params.event}/dashboard`);
  // }

  const onSubmit = handleSubmit(async (data: any) => {
    if (
      asEnumerable(eventContext.event?.resources || []).Any(
        (r: any) => r.key == 'recaptcha' && !isVerified,
      ) ||
      (forceRecaptcha && !isVerified)
    ) {
      addToast({
        type: 'error',
        title: t('Authentication error'),
        description: t('Please verify that you are a human!'),
      });
      return;
    }

    try {
      setLoading(true);
      const schema = Yup.object().shape({
        email: Yup.string().required(t('Please fill in the field')),
        //.email('Digite um e-mail válido'),
        password: Yup.string().min(
          5,
          t('Password must be at least 5 characters'),
        ),
      });

      await schema.validate(data, {
        abortEarly: true,
      });

      //FixedPassword
      if (
        eventContext.event?.settings?.password &&
        eventContext.event?.settings?.password != ' '
      ) {
        data.password = eventContext.event?.settings?.password;
      }
      // await signIn({
      //   email: `${props.params.event}:${data.email}`,
      //   password: data.password
      // });

      data.email = data.email.toLowerCase();
      const pagestart = eventContext.event?.pagestart;
      await signIn({
        email: data.email,
        password: data.password,
        eventKey: props.params.event,
        pagestart,
        event: eventContext?.event,
      });
    } catch (error) {
      const _error: any = error;

      console.log('login error', _error);

      if (
        (_error?.response?.data?.message || _error.message) ===
        'Login ou senha inválida'
      ) {
        LoginUtils.increaseAttemps();
        setisVerified(false);
        recaptchaRef?.current?.reset();
        const _attemps = LoginUtils.getAttemps();
        if (LoginUtils.attempsExceeded()) {
          setForceRecaptcha(true);
        }
      }

      setLoading(false);
      addToast({
        type: 'error',
        title: t('Authentication error'),
        description:
          _error?.response?.data?.message ||
          _error.message ||
          t('An error occurred while signing in, please try again'),
      });
    }
  });

  const passwordValidation = (password: string) => {
    let validation = <></>;

    // Validate lowercase letters
    var lowerCaseLetters = /[a-z]/g;
    // if (!password.match(lowerCaseLetters)) {
    //   validation = (
    //     <React.Fragment>
    //       {' '}
    //       {validation}{' '}
    //       <span className="error">
    //         {t('Password must have one character uppercase')}
    //       </span>
    //       <br />
    //     </React.Fragment>
    //   );
    // }

    // Validate capital letters
    // var upperCaseLetters = /[A-Z]/g;
    // if (!password.match(upperCaseLetters)) {
    //   validation = <React.Fragment> {validation} <span className="error">A senha deve ter uma letra maiúscula</span><br /></React.Fragment>
    // }

    // Validate numbers
    // var numbers = /[0-9]/g;
    // if (!password.match(numbers)) {
    //   validation = (
    //     <React.Fragment>
    //       {' '}
    //       {validation}{' '}
    //       <span className="error">
    //         {t('The password must have at least one number')}
    //       </span>
    //       <br />
    //     </React.Fragment>
    //   );
    // }

    // // Validate length
    // if (password.length < 6) {
    //   validation = <React.Fragment> {validation} <span className="error">A senha deve ter ao menos 6 caracteres</span><br /></React.Fragment>
    // }

    setPasswordMessage(validation);

    return validation !== <></>;
  };
  const countriesByLang: any = { pt: 'BR', en: 'US', es: 'ES' };
  const langByCode: any = { BR: 'pt', US: 'en', ES: 'es' };
  function setLanguage(language: string) {
    if (!language || language === 'undefined') return;
    const a = countriesByLang[language];
    console.log('setLanguage', {
      language,
      valid: !language,
      a: langByCode[a],
    });

    i18n.changeLanguage(langByCode[a]);

    localStorage.setItem('defaultLanguage', langByCode[a]);
  }

  function verifyCallback(response: any) {
    if (response) {
      setisVerified(true);
    }
  }

  useEffect(() => {
    if (LoginUtils.attempsExceeded()) {
      setForceRecaptcha(true);
    }
  }, []);

  return (
    <DefaultMasterPage {...props} scheme="frm-signin">
      <AnimationContainer>
        <div className="d-flex justify-content-center b-b pt-5">
          <img
            className="mb-4 logo"
            src={eventContext.event?.customization?.logo}
          />
        </div>

        {/* <Languages data={eventContext.event?.settings?.languages}></Languages> */}
        <div className="viewLangs">
          {eventContext.event?.settings?.languages.map((r: any) => {
            return (
              <>
                <div style={{ padding: 2 }} />
                <img
                  onClick={() => setLanguage(r?.language)}
                  src={r?.flag}
                  style={{
                    cursor: 'pointer',
                    width: 25,
                    height: 25,
                  }}
                />
                {/* <div
                  onClick={() => setLanguage(r?.language)}
                  style={{
                    cursor: 'pointer',
                    backgroundImage: `url(${r?.flag})`,
                    backgroundSize: 'cover',
                    width: 25,
                    height: 25,
                  }}
                /> */}
                <div style={{ padding: 2 }} />
              </>
            );
          })}
        </div>
        <p className="login-title">{t('Platform')}</p>

        <form onSubmit={onSubmit} style={{ width: '100%', minWidth: '330px' }}>
          {!!eventContext.event?.settings?.newSignin?.placeholder && (
            <>
              <input
                className="form-control form-text"
                name="email"
                type="text"
                data-cy="email"
                ref={register({ required: true })}
                placeholder={
                  eventContext.event?.settings?.newSignin?.placeholder
                }
              />
              <>
                {errorsAny[`email`] && (
                  <span className="error">{t('Platform')}</span>
                )}
              </>
            </>
          )}
          {!eventContext.event?.settings?.newSignin?.placeholder && (
            <>
              <input
                className="form-control form-text"
                name="email"
                type="text"
                data-cy="email"
                ref={register({ required: true })}
                placeholder={t('Email')}
              />
              <>
                {errorsAny[`email`] && (
                  <span className="error">{t('E-mail is required')}</span>
                )}
              </>
            </>
          )}

          {!eventContext.event?.settings?.password && (
            <>
              <input
                className="form-control form-text"
                name="password"
                type="password"
                data-cy="password"
                value={eventContext.event?.settings?.password}
                ref={register({ required: true, validate: passwordValidation })}
                placeholder={t('Password')}
              />
              {errorsAny[`password`] && (
                <span className="error">{t('Password is required')}</span>
              )}
              <br />
              {passwordMessage}
            </>
          )}
          <ContainerButton
            loading={loading}
            classe={'button_login'}
            data-cy="submit"
          >
            {t('Signin')}
          </ContainerButton>

          {(asEnumerable(eventContext.event?.resources || []).Any(
            (r: any) => r.key == 'recaptcha',
          ) ||
            forceRecaptcha) && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Recaptcha
                ref={recaptchaRef}
                sitekey="6LfkrK4eAAAAAGQSy388DeRcLjlojn4dNLnSUnzX"
                render="explicit"
                verifyCallback={(res) => verifyCallback(res)}
                className="g-recaptcha"
              />
            </div>
          )}

          {!eventContext.event?.settings?.password &&
            !eventContext.event?.settings?.password &&
            eventContext.event?.forgotPassword && (
              <>
                <Link
                  className="link"
                  to={`/${props.params.event}/forgot-password`}
                >
                  {t('Forgot password')}
                </Link>
              </>
            )}
          {eventContext.event?.newAccount && (
            <>
              <a
                className="link"
                href={
                  eventContext.event?.settings?.login?.signup_url
                    ? eventContext.event?.settings?.login?.signup_url
                    : `/${props.params.event}/signup`
                  // ||
                  // `/${props.params.event}`
                  // `/${props.params.event}/signup`
                }
              >
                {t('Create a new account')}
              </a>
            </>
          )}

          {!!eventContext?.event?.settings?.helpdesk_languages && (
            <>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '10px',
                  borderTop: '1px solid #666',
                }}
              >
                <div style={{ maxWidth: '349px' }}>
                  <strong>
                    {
                      eventContext?.event?.settings?.helpdesk_languages?.title[
                        i18n.language
                      ]
                    }
                  </strong>
                  <br></br>
                  {
                    eventContext?.event?.settings?.helpdesk_languages
                      ?.description[i18n.language]
                  }
                  <br></br>
                  {event === 'provendasetopvendas' && (
                    <a
                      target="_blank"
                      href={`${
                        eventContext?.event?.settings?.helpdesk_languages?.link[
                          i18n.language
                        ]
                      }`}
                    >
                      <img src={whatsappIcon} alt="Whatsapp" />{' '}
                    </a>
                  )}

                  <em>
                    <a
                      target="_blank"
                      href={`${
                        eventContext?.event?.settings?.helpdesk_languages?.link[
                          i18n.language
                        ]
                      }`}
                      style={{ color: '#000' }}
                    >
                      {
                        eventContext?.event?.settings?.helpdesk_languages?.data[
                          i18n.language
                        ]
                      }
                    </a>
                  </em>
                </div>
              </div>
            </>
          )}

          {eventContext?.event?.settings?.helpdesk &&
            !eventContext?.event?.settings?.helpdesk_languages && (
              <>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '10px',
                    borderTop: '1px solid #666',
                  }}
                >
                  <div style={{ maxWidth: '349px' }}>
                    <strong>
                      {eventContext?.event?.settings?.helpdesk?.title}
                    </strong>
                    <br></br>
                    {eventContext?.event?.settings?.helpdesk?.description}
                    <br></br>
                    <em>
                      <a
                        target="_blank"
                        href={`${eventContext?.event?.settings?.helpdesk?.link}`}
                      >
                        {eventContext?.event?.settings?.helpdesk?.phone}
                      </a>
                    </em>
                  </div>
                </div>
              </>
            )}
        </form>
      </AnimationContainer>
    </DefaultMasterPage>
  );
};

export default SignIn;
