import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';
//import mainBackground from '../../assets/images/wxYi9eq.png';

interface ContainerPros {
  customization: any;
}

export const Container = styled.div<ContainerPros>`
${(props) => css`
      background: linear-gradient(
        -45deg,
        ${props.customization?.lobbyBgColor} 20%,
        ${ props.customization?.lobbyBgColor} 80%
      );
`}

form {
  padding:15px;
}
.form-s {
  text-align: center;
}
a.link {
  font-size: 15px;
}

img.imagem-logo{
  position: absolute;
  width:150px;
  top: 70px;
}
@media only screen and (max-width: 1000px){
  :root{
      overflow-y: auto;
      overflow-x: hidden;
  }
  img.imagem-logo{
    width:100px;
  }
  .logo-cliente {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }


  .content-page-login{
    display: grid;
    grid-template-rows: 100vw 1fr !important;
    grid-template-columns: 100vw !important;
}
  .bg_client{
      height: 100vw !important;
      width: 100vw !important;
  }
  .frm-default{
      height: 70vh!important;
      width: 100vw !important;
      min-height: 25vh !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  }
  .bg_client>img {
      width: 100% !important;
      height: 100vw !important;
  }
}

.content-page-login{
  display: grid;
  grid-template-columns: 100vh 1fr;
  grid-template-rows: 100vh;
  justify-content: center;
  align-content: center;
}
.bg_client{
  height: 100vh;
  width: 100vh;
}
.bg_client>img {
  height: 100vh;
  width: 100vh;
}

/*.frm-home{
  background:blue;
}
.frm-recovery{
  background:red;
}
.frm-signup{
  background:yellow;
}

.frm-signin{
  background:#313131;
}
*/

.frm-home,
.frm-recovery,
.frm-signin,
.frm-signup,
.frm-default{
  height: 100%;
  width:100%;
  min-width: 25vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/*recovery*/
@media only screen and (max-width: 557px){
  .frm-recovery form {
    margin-top: 40px!important;
  }
}

/*Signin*/
.frm-signin .login-title {
    padding: 0 5px;
}
`;
