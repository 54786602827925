import React, { useRef, useEffect, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import './styles.css';
import { uuid } from 'uuidv4';
import moment from 'moment';
import Comment from './Comment';
import { Icon } from '../../Icons';
import ModalTimeline from '../ModalTabs/ModalTimeline';
import { useEvent } from '../../../hooks/EventContext';
import firebase from 'firebase';
import { Card, Form, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useAuth } from '../../../hooks/Auth2';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/Toast';
import { useForm } from 'react-hook-form';
import avatarImg from '../../../assets/images/avatar.png';
import { Conteiner } from './style';
import { useTranslation } from 'react-i18next';

interface IProps {
  handleModal: () => void;
  setContentModalTimeline: (content: React.ReactNode) => void;
}
const Timeline: React.FC<IProps> = (props: any) => {
  const ref = useRef(null);
  const [timeline, setTimeline] = useState<any[]>([]);
  const firestore = firebase.firestore();
  const { onMouseDown } = useDraggableScroll(ref, {
    direction: 'horizontal',
  });
  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const { addToast } = useToast();
  const eventContext = useEvent();
  const { getUser } = useAuth();
  const user = getUser();
  const [sending, isSending] = useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    try {
      if (eventContext.event?.id) {
        const unsubscribe = firestore
          .collection('timeline')
          .where('eventId', '==', eventContext.event.id)
          .where('channelId', '==', eventContext.channel?.id || 'lobby')
          // .orderBy('createdAt', 'desc')
          .onSnapshot((snapshot) => {
            console.log('timeline snapshot', snapshot.docs.length);
            const items = snapshot.docs.map((a) => {
              return { ...a.data(), id: a.id };
            });
            setTimeline(
              items.sort((x: any, y: any) => {
                return y.createdAt - x.createdAt;
              }),
            );
          });

        return () => {
          unsubscribe();
        };
      }
    } catch (error) {
      console.log('timeline snapshot error', error);
    }
  }, [eventContext.event?.id, eventContext.channel?.id]);

  function changeModal(timelineInfo: any) {
    props.setContentModalTimeline(<ModalTimeline item={timelineInfo} />);
    props.handleModal();
  }

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      if (!user) return;
      isSending(true);
      const schema = Yup.object().shape({
        userText: Yup.string().required('O campo de mensagem é obrigatório'),
        userImg: Yup.mixed().required('Por favor selecione uma imagem.'),
      });
      await schema.validate(data, {
        abortEarly: true,
      });
      let imgUrl: string = '';
      if (data.userImg.length) {
        const uploaded: any = await firebase
          .storage()
          .ref(`/companies/${props.params.event}/files/${uuid()}`)
          .put(data.userImg[0]);
        imgUrl = (await uploaded.ref.getDownloadURL()) || '';
        setIsImageSelected(false);
      }
      else {
        isSending(false);
        addToast({
          type: 'error',
          title: `${t("Error on Send")}`,
          description: `${t("Please select an image")}`,
        });
        return;
      }

      const entity: any = {
        createdAt: moment.utc().valueOf(),
        text: data.userText,
        channelId: eventContext.channel?.id || 'lobby',
        eventId: eventContext.event.id,
        user: {
          name: user.name,
          email: user.email,
          avatar: user.avatar || avatarImg,
        },
        uid: user.id || user.uid,
      };

      if (imgUrl) {
        entity.imgUrl = imgUrl;
      }

      await firestore.collection('timeline').add(entity);

      setValue('userText', '');

      addToast({
        type: 'success',
        title: `${t("Post Sent")}`,
        description: `${t("Your message has been sent successfully!")}`,
      });

      isSending(false);
    } catch (error) {
      isSending(false);
      alert(error.message);
      addToast({
        type: 'error',
        title: `${t("Error on Send")}`,
        description:
          error?.response?.data?.message ||
          error.message ||
          `${t("Error on send, try again")}`,
      });
    }
  });

  function onChange(e: any) {
    const file = e.target.files[0];
    if (!file) {
      setIsImageSelected(false);
    }
    else {
      setIsImageSelected(true);
    }
  }

  function like(timeline: any) {
    if (!user) return;
    const ref = firestore.collection('timeline').doc(timeline.id);
    timeline.likesObj = timeline.likesObj || {};
    if (!timeline.likesObj[user.id]) {

      timeline.likesObj = { ...timeline.likesObj, [user.id]: user.name };

      return ref.update({
        likes: firebase.firestore.FieldValue.increment(1),
        likesObj: timeline.likesObj,
      });
    } else {
      delete timeline.likesObj[user.id];
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(-1),
        likesObj: timeline.likesObj,
      });
    }
  }

  function textSubstring(str: string) {
    if (str.length > 150) return str.substring(0, 155) + '...';

    return str;
  }

  return (
    <Conteiner customization={eventContext?.event?.customization || {}}>
      <Form
        className="form-envio w-100 form-row pt-4 pr-3"
        onSubmit={onSubmit}
      >
        <Form.Group
          className="col-12 col-lg-5"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Control
            name="userText"
            ref={register}
            placeholder="Envie uma mensagem."
          />
        </Form.Group>

        <Form.Group className="col-12 col-lg-4">
          <Form.File
            name="userImg"
            ref={register}
            id="custom-file"
            label={isImageSelected ? "Imagem selecionada" : "Selecione uma imagem"}
            custom
            onChange={onChange}
          />
        </Form.Group>

        <Form.Group className="col-12">
          {!sending ? (
            <Button className="buttonSendMessage" type="submit">
              {!sending && (
                <>
                  {`${t("Send")}`}
                  <i
                    style={{ fontSize: '1rem' }}
                    className="fa fa-paper-plane text-white"
                  />
                </>
              )}
            </Button>
          ): (
            <Button className="buttonSendMessage" type="submit">
              <Spinner animation="border" role="status" size="sm">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Button>
          )}
        </Form.Group>
      </Form>
      <div className="cards-timeline">
        {timeline.map((timelineInfo: any) => (
          <div className={timelineInfo?.likes ? "card text-lefm" : "reducedCard text-lefm"}>
            <div className="header-timeline">
              <div className="nav-timeline">
                <img className="avatar" src={timelineInfo?.user?.avatar} />
                <h6 className="">{timelineInfo?.user.name}</h6>
              </div>
              <p className="date marginCust">
                {moment(timelineInfo.createdAt).format('DD-MM-YYYY HH:mm:ss')}
              </p>
            </div>
            <div className="img-timeline">
              <img className="img-fluid" src={timelineInfo.imgUrl} />
            </div>
            <div className="content-timeline">
              <div className="timeline-actions">
                <div className="content-timeline-info">
                  <button className="timeline-countdown-like" onClick={() => changeModal(timelineInfo as any)}>
                    {/* <button className="timeline-countdown-like" onClick={() => {}}> */}
                    {/* <Comment {...props} item={timelineInfo} /> */}
                    <span className="timeline-icon timeline-icon-comment"><Icon name="iconComment" /></span>
                    <span>{timelineInfo?.comments?.length || 0}</span>
                  </button>
                  <button className="timeline-countdown-comments" onClick={() => like(timelineInfo)}>
                    <span className="timeline-icon timeline-icon-heart"><Icon name="iconHeart" /></span>
                    <span>{timelineInfo?.likes || 0}</span>
                  </button>
                </div>
              </div>
              <div className="content-timeline">
                <div className="content-timeline-text">
                  <p className="marginCust2">
                    {
                      timelineInfo?.likes ? (
                        <>
                          {t("Liked by")}&nbsp;<strong>{Object.entries(timelineInfo?.likesObj)[0][1] as string}</strong>
                          {
                            timelineInfo?.likes > 1 && (
                              <strong>&nbsp;{t("Others")} {timelineInfo?.likes -1 || 0} {timelineInfo?.likes === 1 ? t("People") : t("Person")}</strong>
                            )
                          }
                          <br />
                        </>
                      ) : ""
                    }
                  </p>
                  <p
                    className="fontweight marginCust2"
                    style={{ fontWeight: 'normal' }}
                  >
                    {/* <strong>{timelineInfo?.comments?.[0]?.user?.name} </strong>{timelineInfo?.comments?.[0]?.comment} */}
                    <strong>
                      {textSubstring(timelineInfo.text)}
                    </strong>
                    {/* <br /> */}
                    {/* <a href="https://www.instagram.com/explore/tags/goadiaries/">
                  #travel
                </a>
                &nbsp;
                <a href="https://www.instagram.com/explore/tags/goadiaries/">
                  #explore
                </a>
                &nbsp; */}
                    {/* <br /> */}
                  </p>
                </div>
              </div>
            </div>
            <div className="timeline-footer">
              <p className="date marginCust" onClick={() => changeModal(timelineInfo as any)}>
                {t("See all")} {timelineInfo?.comments?.length || 0} {t("Comments")}
                <br />
              </p>
            </div>
          </div>
        ))}
      </div>
    </Conteiner>
  );
};
export default Timeline;
