import React, { ReactElement, useEffect, useRef } from 'react';
import { TabPanel } from './tab-panel';
import { TabButton } from './tab-button';
import './tabs.css';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useTheme} from '../../hooks/ThemeContext';
import {TabButtons} from './style';
import useDraggableScroll from 'use-draggable-scroll';
interface IProps {
  selected: string;
  onSelect: (id: string) => void;
  theaterMode?: boolean;
  customization?: any;
}
export const Tabs: React.FC<IProps> = ({ selected, onSelect, theaterMode, customization, children }) => {
  const tabButtonsRef = useRef() as React.RefObject<HTMLDivElement>;
  const tabIndicatorRef = useRef() as React.RefObject<HTMLDivElement>;
  const tabPanelsRef = useRef() as React.RefObject<HTMLDivElement>;
  const tabTrackerRef = useRef() as React.RefObject<HTMLDivElement>;
  const {theme} = useTheme();
  const ref = useRef(null);

  const { onMouseDown } = useDraggableScroll(tabButtonsRef, {
    direction: 'horizontal',
  });

  interface IPropsChild {
    props: {
      id: string;
      label: string;
      iconFontAwesome?: IconProp;
      icon?: JSX.Element;
    }
  }
  const buttons = React.Children.map(children as IPropsChild, child => {
    const isSelected = selected === child?.props.id;
    const handleClick = () => onSelect(child?.props.id);

    return child?.props && (
      <TabButton ref={ref} id={child?.props.id} selected={isSelected} onClick={handleClick} >
        <div className="tab-icon">
          {
            child?.props?.iconFontAwesome ?
              <FontAwesomeIcon icon={child?.props?.iconFontAwesome} />
              : <div className="tab-icon-svg">{child?.props?.icon}</div>
          }
        </div>
        {child?.props.label}
      </TabButton>
    );
  });
  interface IPropsPanels {
    props: {
      id: string;
      children: React.ReactNode
    }
  }
  const panels = React.Children.map(children as IPropsPanels, child => {

    const id = child?.props.id;
    const isSelected = selected === id;

    return (
      <TabPanel id={id} selected={isSelected}>
        {
          child?.props.children
        }
      </TabPanel>
    );
  });

  useEffect(() => {
    if (tabButtonsRef.current && tabIndicatorRef.current) {
      updateTabIndicator(
        tabButtonsRef.current,
        tabIndicatorRef.current,
        selected
      );
    }
    if (tabPanelsRef.current && tabTrackerRef.current) {
      updateTabTrackerPosition(
        tabPanelsRef.current,
        tabTrackerRef.current,
        selected
      );
    }
  }, [selected]);

  useEffect(() => {
    if (tabPanelsRef.current && tabTrackerRef.current && panels) {
      updateTabTrackerWidth(
        tabPanelsRef.current,
        tabTrackerRef.current,
        panels?.length
      );
    }
  }, [panels?.length]);

  useEffect(() => {
    const handleResize = () => {
      if (tabButtonsRef.current && tabIndicatorRef.current && tabPanelsRef.current && tabTrackerRef.current && panels) {
        updateTabIndicator(
          tabButtonsRef.current,
          tabIndicatorRef.current,
          selected
        );
        updateTabTrackerWidth(
          tabPanelsRef.current,
          tabTrackerRef.current,
          panels?.length
        );
        updateTabTrackerPosition(
          tabPanelsRef.current,
          tabTrackerRef.current,
          selected
        );
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [selected, panels?.length]);

  return (
    <div className="tabs">
      <TabButtons theaterMode={theaterMode} theme={theme} customization={customization} ref={tabButtonsRef} onMouseDown={onMouseDown}>
        {buttons}
        <span className="tab-indicator" ref={tabIndicatorRef} />
      </TabButtons>
      <div className={theaterMode ? 'tabs__panels__theater' : "tabs__panels"} ref={tabPanelsRef}>
        <div className="tabs__tracker" ref={tabTrackerRef}>
          {panels}
        </div>
      </div>
    </div>
  );
}




function updateTabIndicator(tabButtons: HTMLDivElement, tabIndicator: HTMLElement, selected: string) {
  const tabButton = tabButtons?.querySelector(`[data-id="${selected}"]`) as HTMLElement;
  const tabButtonsPos = tabButtons?.getBoundingClientRect();
  const tabButtonPos = tabButton?.getBoundingClientRect();
  const left = (tabButtons.scrollLeft + tabButtonPos?.left) - tabButtonsPos?.left;
  const width = tabButton?.clientWidth;
  tabIndicator.style.width = `${width}px`;
  tabIndicator.style.left = `${left}px`;
}

function updateTabTrackerWidth(tabPanels: HTMLElement, tabTracker: HTMLElement, count: number) {
  const width = tabPanels.clientWidth * count;
  tabTracker.style.width = `${width}px`;
}

function updateTabTrackerPosition(tabPanels: HTMLElement, tabTracker: HTMLElement, selected: string) {
  const tabPanel = tabTracker.querySelector(`[data-id="${selected}"]`);
  const index = Array.prototype.indexOf.call(tabTracker.children, tabPanel);
  const x = tabPanels.clientWidth * -index;
  tabTracker.style.transform = `translateX(${x}px)`;
}
interface IPropsTab {
  label: string;
  id: string;
  icon?: JSX.Element;
  iconFontAwesome?: IconProp;
}
export const Tab: React.FC<IPropsTab> = ({ label, id, children, icon, iconFontAwesome }) => {
  return <div>{children}</div>;
}
