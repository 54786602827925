import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../services/api';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import config from '../config';

const Firebase = createContext<any>(firebase);
firebase.initializeApp(config);

const FirebaseProvider: React.FC = ({ children }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    console.log('initialize firebase', firebase);
    // if (!initialized && firebase) {
    //   setInitialized(true);
    // }

    if (firebase.apps.length) setInitialized(true);
  }, [firebase]);

  if (!initialized) return null;
  return <Firebase.Provider value={firebase}>{children}</Firebase.Provider>;
};

function useFirebase(): any {
  const context = useContext(Firebase);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { FirebaseProvider, useFirebase };
