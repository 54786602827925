import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import React from 'react';
import ptbr from '../assets/locales/pt-br.json';
import enus from '../assets/locales/en-us.json';
import es from '../assets/locales/es-es.json';
import wd from '../assets/locales/wd-wd.json';

import { useEvent } from './EventContext';

const I18Provider: React.FC = ({ children }) => {
  const eventContext = useEvent();

  /* LANGUAGE */
  localStorage.removeItem('defaultLanguage');
  let lang = eventContext.eventKey === "volpecapital-convidado" || eventContext.eventKey === 'volpecapital-investidor' ?
  localStorage.getItem('defaultLanguage') ||
  eventContext.event?.settings?.languages[0]?.language ||
  `en` :
  localStorage.getItem('defaultLanguage') ||
  eventContext.event?.settings?.languages[0]?.language ||
  `pt`
;

  if (lang && lang !== 'undefined')
    localStorage.setItem('defaultLanguage', lang);
  else localStorage.setItem('defaultLanguage', 'pt');

  console.log('defaultLanguage: ', localStorage.getItem('defaultLanguage'));
  if (
    !localStorage.getItem('defaultLanguage') &&
    eventContext.event?.settings?.languages
  ) {
    eventContext.event?.settings?.languages.forEach(function (
      item: any,
      index: number,
    ) {
      if (item.default == true) {
        lang = item.language;
        localStorage.setItem('defaultLanguage', lang);
      }
    });
  }
  /* LANGUAGE */

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: enus,
        pt: ptbr,
        es: es,
        wd: wd,
      },
      lng: lang,
      fallbackLng: lang,

      interpolation: {
        escapeValue: false,
      },
    });

  return <>{children}</>;
};

export { I18Provider };
