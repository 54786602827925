/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { IUser, useAuth } from './Auth2';
import { useEvent } from './EventContext';
import Emitter from '../utils/Event';

const PresenceContext = createContext<PresenceData>({} as PresenceData);
// Object.freeze(PresenceContext);
export { PresenceContext };

const firestore = firebase.firestore();

export interface PresenceData {
  totalOnline: number;
  participantsOnline: any[];
  registerPresence(eventId: string, channelId: string): Promise<void>;
  getTotal(eventId: string, channelId: string): void;
  updatePresence(eventId: string, channelId: string): void;
  dispose(): void;
  gap: number;
}

const PresenceProvider: React.FC<any> = ({ children, match }) => {
  const { event } = useEvent();
  const gap = 1000 * 60 * 3; // seconds gap to check online users
  // const gap = 1000 * 1 * 30; // seconds gap to check online users
  const { getUser } = useAuth();
  const user = getUser() || ({} as Partial<IUser>);
  const [totalOnline, setTotalOnline] = useState<number>(0);
  const [intervals, setInvervals] = useState<number[]>([]);
  const [presences, setPresences] = useState<any[]>([]);
  const [participants, setParticipants] = useState<any[]>([]);
  const [participantsOnline, setParticipantsOnline] = useState<any[]>([]);

  function getTotal($eventId: string, $channelId: string): void {
    const time = new Date().getTime() - gap * 2.5;
    console.log('PresenceContext getTotal interval', {
      time,
      date: new Date(time),
      $eventId,
      $channelId,
    });
    firebase
      .firestore()
      .collection('presence')
      .where('eventId', '==', $eventId)
      .where('channelId', '==', $channelId)
      .where('ping', '>=', time)
      .get()
      .then((snap) => {
        const $presence = snap.docs.map((v) => ({ id: v.id, ...v.data() }));

        // console.log('Presence', $presence.length);
        const $presences = $presence?.filter((p: any) => {
          const diff = new Date().getTime() - p.ping;
          const validation = diff <= gap;

          // console.log('Presence diff', { diff, p, validation });

          return validation;
        });
        setPresences($presences);
        setTotalOnline($presences.length);

        console.log('Get presence', { presences: $presences.length });
      })
      .catch((e) => {
        console.log('Get presence error', e);
      });
  }

  async function updatePresence(
    $eventId: string,
    $channelId: string,
  ): Promise<void> {
    console.log('PresenceContext updatePresence interval', {
      $channelId,
      $eventId,
    });
    const presenceRef = await firestore
      .collection('presence')
      .where('eventId', '==', $eventId)
      .where('channelId', '==', $channelId)
      .where('userId', '==', user?.id);

    let $presence = null;

    const data: any = {
      channelId: $channelId,
      eventId: $eventId,
      userId: user?.id,
      ping: moment().utc().valueOf(),
      createdAt: moment().utc().valueOf(),
      updatedAt: moment().utc().valueOf(),
    };

    try {
      const query = await presenceRef.get();
      $presence = query?.docs?.[0]?.data();
      $presence.id = query?.docs?.[0].id;

      if ($presence) {
        data.id = $presence.id;
        data.createdAt = $presence.createdAt;
        data.updatedAt = $presence.updatedAt;
        data.lastJoin = $presence.lastJoin;
      }

      // if (!$presence) {
      // await presenceRef.set(data);
      if (data.id)
        await firestore.collection('presence').doc(data.id).set(data);
      else await firestore.collection('presence').doc().set(data);

      // }
    } catch (error) {
      if (!$presence) {
        // await presenceRef.set(data);
        await firestore.collection('presence').doc().set(data);
        // const query = await presenceRef.get();
        // $presence = query.data();
      }
    }
  }

  async function registerPresence(
    $eventId: string,
    $channelId: string,
  ): Promise<void> {
    try {
      console.log('***** PresenceContext registerPresence *****');

      const presenceRef = await firestore
        .collection('presence')
        .where('eventId', '==', $eventId)
        .where('channelId', '==', $channelId)
        .where('userId', '==', user?.id);
      let $presence = null;

      const data: any = {
        channelId: $channelId,
        eventId: $eventId,
        userId: user?.id,
        ping: moment().utc().valueOf(),
        lastJoin: moment().utc().valueOf(),
        createdAt: moment().utc().valueOf(),
        updatedAt: moment().utc().valueOf(),
      };

      try {
        const query = await presenceRef.get();
        $presence = query?.docs?.[0]?.data();
        $presence.id = query?.docs?.[0]?.id;

        if ($presence) {
          data.id = $presence.id;
          data.createdAt = $presence.createdAt;
          data.updatedAt = $presence.updatedAt;
        }

        if (data.id) firestore.collection('presence').doc(data.id).set(data);
        else await firestore.collection('presence').doc().set(data);
      } catch (error) {
        if (!$presence) {
          firestore.collection('presence').doc().set(data);
        }
      }

      // console.log('registerPresence', { data: $presence });
    } catch (error) {
      console.log('registerPresence error', { error });
    }
  }

  const presenceDispose = React.useCallback((): void => {
    console.log('presenceDispose', { intervals });
    // if (intervals.length) {
    //   intervals.map((v) => v && clearInterval(v));
    //   setInvervals([]);
    // }
  }, [intervals]);

  useEffect(() => {
    Emitter.emit('PARTICIPANTS.ONLINE', participantsOnline);
  }, [participantsOnline]);

  useEffect(() => {
    Emitter.emit('PARTICIPANTS.QTD_ONLINE', totalOnline);
  }, [totalOnline]);

  useEffect(() => {
    if (!event?.id || !user?.isModerator || !presences || !presences.length)
      return () => {};
    // console.log('PresenceContext load Online Users', event?.id);
    (async () => {
      try {
        const userIds = presences
          .sort((a, b) => {
            if (a.lastJoin > b.lastJoin) return -1;
            if (a.lastJoin < b.lastJoin) return 1;
            return 0;
          })
          .map((p) => p.userId)
          .slice(0, 100);

        console.log('presence load participants', {
          ids: userIds.length,
          presences,
          userIds,
        });

        const presenceObj: any = {};
        presences.forEach((v) => {
          presenceObj[v.userId] = v;
        });
        const $participants: any[] = [];
        let page = 1;
        const take = 10;
        const limit =
          Object.keys(presenceObj).length > 100
            ? 100
            : Object.keys(presenceObj).length;

        do {
          try {
            const start = (page - 1) * take;
            const ids = userIds.slice(start, start + take);
            console.log('presence participants', { ids, start, page, take });
            const snap = await firestore
              .collection('participant')
              .where('eventId', '==', event.id)
              .where('id', 'in', ids)
              .get();

            snap.docs.forEach((doc) => {
              $participants.push({
                id: doc.id,
                userId: doc.id,
                lastJoin: presenceObj?.[doc.id]?.lastJoin,
                ...doc.data(),
              });
            });

            page++;
          } catch (error) {
            console.log('error into persence participants loop');
            break;
          }
        } while (limit > page * take);

        // console.log('presence load participants', $participants);

        setParticipants($participants);
      } catch (error) {
        console.log('error on load participants into precense hook', error);
      }
    })();
  }, [event?.id, presences]);

  useEffect(() => {
    if (!presences || !participants) return;

    // console.log('presence load user online effect', {
    //   participants,
    //   presences,
    // });

    setParticipantsOnline(
      participants.filter(
        (participant) =>
          presences.findIndex(
            (presence) => presence.userId === participant.id,
          ) >= 0,
      ),
    );
  }, [participants, presences]);

  return (
    <>
      <PresenceContext.Provider
        value={{
          registerPresence,
          getTotal,
          updatePresence,
          totalOnline,
          participantsOnline,
          dispose: presenceDispose,
          gap,
        }}
      >
        {children}
      </PresenceContext.Provider>
    </>
  );
};

function usePresence(): PresenceData {
  const context = useContext(PresenceContext);

  if (!context) {
    throw new Error('usePresence must be used within an PresenceProvider');
  }

  return context;
}

export { PresenceProvider, usePresence };
