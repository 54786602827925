import React, { useEffect, useState } from 'react';
import { useEvent } from '../../hooks/EventContext';
import { asEnumerable } from 'linq-es2015';

import './style.css';

import CardSlideChannel from '../../components/NewsFeatures/CardSlideChannel';
import Timeline from '../../components/NewsFeatures/Timeline';
import { Tabs, Tab } from '../../components/Tabs/tabs';
import useParam from '../../components/Tabs/use-param';
import { ModalTabs } from './ModalTabs';
import { ModalTimeline } from './ModalTabs/ModalTimeline';

import { Icon, IconsList } from '../Icons';

import Disclaimer from '../Features/Disclaimer';
import Channels from '../Features/Channels/index';
import Word3dV2 from '../Word3dV2';
import Word3d from '../Word3d';
import Iframe from 'react-iframe';
import { useTranslation } from 'react-i18next';
import Gallery from './Gallery';
import { useAuth } from '../../hooks/Auth2';

const NewsFeatures: React.FC = (props: any) => {
  const { getUser } = useAuth();
  const user = getUser();

  const eventContext = useEvent();
  const { i18n, t } = useTranslation();
  const [selectedTab, setSelectedTab] = useParam('tab', 'channels');
  const [isTimeline, setIsTimeline] = useState<boolean>(true);
  const [isChannel, setIsChannel] = useState<boolean>(true);
  const [groups, setGroups] = useState<string[]>();
  const [hasSimpleChannels, setHasSimpleChannels] = useState(false);

  const [modalTimeLine, setModalTimeLine] = React.useState(false);
  const [contentModal, setContentModal] = React.useState(
    null as React.ReactNode,
  );
  const { channels, channel, event } = useEvent();

  function handleModal(): void {
    setModalTimeLine(!modalTimeLine);
  }
  function setContentModalTimeline(content: React.ReactNode): void {
    setContentModal(content);
  }

  const b64EncodeUnicode = (str: any): string => {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
      }),
    );
  };

  useEffect(() => {
    let docs = channels;

    let _userRestrictChannels: string[] = [];

    if (user?.restrictChannels) {
      _userRestrictChannels = Object.keys(user?.restrictChannels);

      console.log('restrict', { _userRestrictChannels, docs });

      if (_userRestrictChannels.length) {
        docs = docs.filter(
          (doc) =>
            _userRestrictChannels.findIndex(
              (channelKey) => channelKey?.trim() === doc.key?.trim(),
            ) >= 0,
        );
      }
    }

    const _hasSimpleChannel =
      docs?.filter(
        (d) =>
          d.type_room !== 'eventShowRoom' && !d.customization?.channel_group,
      ).length > 0;

    let channelGroups = docs
      ?.filter((d) => d.customization.channel_group)
      .map<string>((d) => d.customization.channel_group);

    channelGroups = Array.from(new Set<string>(channelGroups));
    channelGroups = channelGroups.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    console.log('looby channel groups', {
      channelGroups,
      _hasSimpleChannel,
      docs,
    });

    if (
      channelGroups?.length &&
      !_hasSimpleChannel &&
      channelGroups.indexOf(selectedTab) < 0
    )
      setSelectedTab(`group_${channelGroups[0]}`);

    setGroups(channelGroups);
    setHasSimpleChannels(_hasSimpleChannel);
  }, [channels]);

  console.log('render NewsFeatures', { selectedTab });

  useEffect(() => {
    let arr = [
      'channels',
      'timeline',
      "cloudword",
      'gallery',
      'externallink',
    ];

    const $features = [];

    for (let i = 0; i < eventContext.channel?.resources.length; i++) {
      let found = arr.find(
        (el) => el == eventContext.channel?.resources[i].key,
      );

      if (found) $features.push(found);
      else{
        $features.push('gallery')
      }
    }

    if ($features.length) {
      setSelectedTab($features[0]);
    }
  }, [props.channelId]);

  return (
    <>
      <Tabs
        selected={selectedTab}
        onSelect={setSelectedTab}
        theaterMode={props.theaterMode}
        customization={props?.customization || {}}
      >
        {
        asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'channels',
        ) && eventContext.channel?.id &&
        groups ? groups.map((g) => (
          <Tab
            key={`group_${g}`}
            id={`group_${g}`}
            label={t('Rooms')}
            icon={IconsList.iconChannels}
          >
            <CardSlideChannel group={g} {...props} />
          </Tab>
        ))
        :
        asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'channels',
        ) &&(
            <Tab id="channels" label={t('Rooms')} icon={IconsList.iconChannels}>
              <CardSlideChannel {...props} />
            </Tab>
          )}

        {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'timeline',
        ) &&
          eventContext.channel?.id && (
            <Tab id="timeline" label="Timeline" icon={IconsList.iconTimeline}>
            {/* <Timeline handleModal={handleModal} setContentModalTimeline={setContentModalTimeline} /> */}
            <Timeline
              user={props.user}
              eventId={eventContext.event?.id}
              channelId={eventContext.channel?.id}
              handleModal={handleModal}
              setContentModalTimeline={setContentModalTimeline}
              {...props}
              />
          </Tab>
        )}

        {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'cloudword',
        ) &&
          eventContext.channel?.id && (
          <Tab id="cloudword" label={t('CloudWord')} icon={IconsList.iconCloudword}>
            <Word3dV2
              // isVisible={currentTab === 'cloudword'}
              user={props.user}
              eventId={eventContext.event?.id}
              channelId={eventContext.channel?.id}
              {...props}
            />
          </Tab>
        )}

        {asEnumerable(eventContext.event?.resources || []).Any(
            (r: any) => r.key == 'videoteca',
          ) &&
            eventContext.channel?.id && (
            <Tab id="gallery" label="Galeria" icon={IconsList.iconGallery}>
              <Gallery
                user={props.user}
                eventId={eventContext.event?.id}
                handleModal={handleModal}
                setContentModalTimeline={setContentModalTimeline}
                {...props}
              />
            </Tab>
          )}

        {eventContext.channel?.resources?.map(
          (channel: any, i: number) =>
            !!channel.tab == true &&
            channel.key == 'externallink' &&
             eventContext.channel?.id && (
              <Tab
                key={`tab_external_link_${i}`}
                id={`tab_external_link_${i}`}
                label={channel?.name_languages[i18n.language]}
                icon={
                  channel?.attachment ? (
                    <img height={20} src={channel?.attachment} />
                  ) : (
                    IconsList.iconStarNew
                  )
                }
              >
                    <Iframe
                      url={eventContext?.channel?.key === 'certificado' ?
                        channel?.link_languages[i18n.language] +
                        '&data=' +b64EncodeUnicode(props?.user?.name +' '+ (props?.user?.fields?.lastname || ''))+'&user='+
                        b64EncodeUnicode(JSON.stringify(props?.user))
                        :
                        channel?.link_languages[i18n.language]
                        // '?iframe=true&nofocus=y' +
                        // '&data=' +
                        // b64EncodeUnicode(props?.user?.name) +
                        // '&user=' +
                        // b64EncodeUnicode(JSON.stringify(props?.user))
                      }
                      width={eventContext?.channel?.key === 'certificado' ? '57%' : '100%'}
                      height="550px"
                      id="myId"
                      className="myClassname"
                      display="block"
                      position="relative"
                      overflow='hidden'
                      allow="camera *;microphone *"
                      onLoad={() => {
                        console.log('external_link iframe load');
                      }}
                    />
              </Tab>
            ),
        )}

        {/* <Tab id="tab4" label="Galeria" icon={iconGallery}>
          <p>Galeria</p>
        </Tab> */}

        {/* {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'externalLink',
        ) &&
          eventContext.channel?.id && (
            <Tab id="tab5" label="Game 1" icon={iconGame}>
              <ExtenalLink
                {...props}
                channel={eventContext.channel}
              ></ExtenalLink>
            </Tab>
          )} */}

        {/* {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'disclaimer',
        ) &&
          eventContext.channel?.id && (
          <Tab id="tab6" label="Disclaimer" icon={iconGame}>
            <Disclaimer/>
          </Tab>
        )} */}

        {/* {eventContext.channel?.resources.map(
          (channel: any, i: number) =>
            !!channel.tab == true &&
            channel.key == 'videoteca' && (
              <Tab id="tab7" label="GalleryVideoTeca" icon={iconGame}>
                <GalleryVideoTeca/>
              </Tab>
            ),
        )} */}
        {/* {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'jitsi',
        ) &&
          eventContext.channel?.id && (
            <Tab
              id="tab78"
              label={t('Event Room')}
            >
              <Jitsi/>
            </Tab>
          )} */}

        {/* {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'whiteboard',
        ) &&
          eventContext.channel?.id && (
            <Tab
              id="tab9"
              label="Whiteboard"
            >
              <Whiteboard/>
            </Tab>
          )} */}

        {/* {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'externalLink',
        ) &&
          eventContext.channel?.id && (
            <Tab
              id="tab10"
              label="externalLink"
              icon={faGamepad}
            >
              <Kahoo/>
            </Tab>
          )} */}

        {/* {asEnumerable(eventContext.channel?.resources || []).Any(
          (r: any) => r.key == 'kahoo',
        ) &&
          eventContext.channel?.id && (
            <Tab
              id="tab11"
              label="Kahoo"
              icon={faGamepad}
            >
              <Kahoo/>
            </Tab>
          )} */}
      </Tabs>
      {modalTimeLine && (
        <ModalTabs onClose={handleModal}>{contentModal}</ModalTabs>
      )}
    </>
  );
};
export default NewsFeatures;
