import React, { FC, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { RiMenuUnfoldFill } from 'react-icons/ri';
import { SidebarData } from './SidebarData';
import Submenu from './Submenu';

import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext';
import { useTheme } from '../../hooks/ThemeContext';
import {
  Icon,
} from '../Icons';
import { asEnumerable } from 'linq-es2015';

const Nav = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 5rem;
`;

const SidebarNav = styled.div<{ sidebar: boolean, theme: any }>`
  width: 250px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.primary};
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 100;
  overflow: auto;
  padding: 2rem 0;
`;

const NavIcon = styled(Link)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 2rem;
  margin: 0;
`;

const SidebarWrap = styled.div``;

const Sidebar: FC = () => {
  const { event, channel } = useParams<{ channel: string; event: string }>();
  const { getUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();
  const { theme } = useTheme();
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = (): void => setSidebar(!sidebar);

  // const menu = SidebarData;
  const menu = SidebarData.filter((data) => {
    if (eventContext?.channels.length <= 1  && data.title === 'Lobby') return false;
    else if (eventContext?.event?.pagestart === "plenaria-virtual" && data.title === 'Room') return false;
    else if (eventContext?.event?.pagestart === "plenaria-virtual" && data.title === 'Lobby') return false;
    else if (eventContext?.event?.pagestart !== "plenaria-virtual" && data.title === 'Lobby 3D') return false;
    else {
      return true;
    }
  })
  // .filter((data) => {
  //   if(!asEnumerable(eventContext.channel?.resources || []).Any(
  //     (r: any) => r.key == 'schedule',
  //   ) && data.title === 'Schedule'){
  //     return false
  //   }
  //   else{
  //     return true;
  //   }
  // });
  // const menu = SidebarData.filter((data) => {
  //   // if (!channel && data.title === 'Room') return false;
  //   // else if (channel) return true;
  //   // else {
  //   //   return true;
  //   // }
  // });

  return (
    <IconContext.Provider value={{ color: '#B9B9B9' }}>
        <Nav>
          <NavIcon to="#" onClick={showSidebar}>
            <Icon name='iconMenu' />
          </NavIcon>
        </Nav>
      <SidebarNav sidebar={sidebar} theme={theme}>
        <SidebarWrap>
          <NavIcon to="#" >
            <AiOutlineCloseCircle onClick={showSidebar} style={{ margin: '0 1rem', position: 'absolute', right: 0 }} />
          </NavIcon>
          <div className="boxAvatar">
            <div>
              {/* <img
                className="avatar-user"
                src={eventContext?.event?.customization?.logo}
                alt=""
              /> */}
            </div>

            <p
              style={{
                textAlign: 'center',
                color: '#616161'
              }}
            >
              {/* {user?.name || user?.email} */}
            </p>
          </div>
          {menu.map((item, index) => {
            return <Submenu item={item} key={index} />;
          })}
        </SidebarWrap>
      </SidebarNav>
    </IconContext.Provider>
  );
};

export default Sidebar;
