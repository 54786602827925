import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as S from './style';
import * as Yup from 'yup';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { useForm } from 'react-hook-form';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { asEnumerable } from 'linq-es2015';
import ContainerButton from '../../ContainerButton';
import { useEvent } from '../../../hooks/EventContext';
import { useAuth } from '../../../hooks/Auth2';

interface ChatProps {
  user: any;
  channelId: string;
  eventId: string;
}

interface LinkEntity {
  channelId: string;
  eventId: string;
  index: number;
  responseIndex: number;
  question: string;
  active: boolean;
  options: { id: string; text: string; index: number }[];
  user: {
    email: string;
    id: string;
  };
}

const Links: React.FC<ChatProps> = (props: any) => {
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    getValues,
    reset,
  } = useForm<LinkEntity>();
  const firestore = firebase.firestore();
  const [links, setLinks] = useState<any[]>([]);
  const [formOptions, setFormOptions] = useState<any[]>([]);
  const [link, setLink] = useState<any>();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { channel, event } = useEvent();
  const { getUser } = useAuth();
  const user = getUser();

  useEffect(() => {
    if (channel?.id) {
      let unsubscribe: any = null;
      unsubscribe = firestore
        .collection('links')
        .where('channelId', '==', channel.id)
        .onSnapshot((snapshot) => {
          const docs = snapshot.docs.map((a) => {
            return { id: a.id, ...a.data() };
          });

          const data = asEnumerable(docs)
            .OrderByDescending((o: any) => o.createdAt)
            .Select((link: any, i) => {
              return link;
            })
            .ToArray();

          setLinks(data);
        });
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [channel?.id]);

  async function editSurvey(link: any) {
    for (const key in link) {
      if (link.hasOwnProperty(key)) {
        const data = link[key];

        if (
          typeof data === 'string' ||
          typeof data === 'number' ||
          typeof data === 'boolean'
        ) {
          setValue(key, data);
        }
      }
    }
    setLink(link);
    setFormOptions(link.options);
  }

  async function cancel() {
    reset();
    setLink(null);
    setFormOptions([]);
  }

  function deleteLink(id: string) {
    if (window.confirm('Tem certeza que deseja deletar este Link?')) {
      const ref = firestore.collection('links').doc(id);
      ref.delete();
    }
  }

  function enableDisableSurvey(data: any) {
    const ref = firestore.collection('links').doc(data.id);
    ref.update({
      active: !data.active,
    });
  }

  function showResponses(data: any) {
    const ref = firestore.collection('links').doc(data.id);
    ref.update({
      showResponses: !data.showResponses,
    });
  }

  function addOption() {
    setFormOptions([...formOptions, {}]);
  }

  function removeOption(ind: number) {
    const arrOptions = [...formOptions];
    arrOptions.splice(ind, 1);
    setFormOptions(arrOptions);
  }

  const onSurveySubmit = handleSubmit(async (data: any) => {
    try {
      if (!user) return;

      const { id, email } = user;

      if (!link?.id) {
        data.user = {
          id,
          email,
        };
        data.createdAt = moment.utc().valueOf();
        data.channelId = channel.id;
        data.eventId = event.id;
      }

      data.options = [];

      let i = 0;
      while (data['opt-' + i]) {
        data.options.push({
          id: i + 1,
          text: data['opt-' + i],
          index: i,
        });
        delete data['opt-' + i];
        i++;
      }
      if (link?.id) {
        const ref = firestore.collection('links').doc(link?.id);
        ref.update(data);
      } else {
        await firestore.collection('links').add(data);
      }

      addToast({
        type: 'success',
        title: 'Link salvo com sucesso',
        description: '',
      });

      reset();
      setFormOptions([]);
      setLink(null);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          error?.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao salvar o Link, tente novamente.',
      });
    }
  });

  return (
    <S.Container>
      <S.Form onSubmit={onSurveySubmit}>
        <label htmlFor="">
          <input
            name="name"
            type="text"
            ref={register({ required: true, maxLength: 200 })}
            placeholder="Nome"
          />
          <>
            {errors.question && (
              <span className="error">O nome do link é obrigatório</span>
            )}
          </>
        </label>

        <label htmlFor="">
          <input
            name="url"
            type="text"
            ref={register({ required: true, maxLength: 200 })}
            placeholder="URL"
          />
          <>
            {errors.question && (
              <span className="error">A url é obrigatória</span>
            )}
          </>
        </label>

        <S.CheckboxLabel>
          <input
            name="active"
            type="checkbox"
            ref={register}
          />
          Ativar Link
        </S.CheckboxLabel>
        <br></br>
        <S.ContainerButtons>
          <ContainerButton
            loading={loading}
            classe="moderator-links-button-save"
          >
            <i className="fa fa-save" style={{ color: '#fff' }}></i> Salvar Link
          </ContainerButton>

          <ContainerButton
            loading={loading}
            classe="moderator-links-button-clear"
            click={cancel}
          >
            <i className="fa fa-times" style={{ color: '#fff' }}></i> Limpar
            Campos
          </ContainerButton>
        </S.ContainerButtons>
      </S.Form>
      <br></br>

      {links.length > 0 && (
        <S.Table>
          <tbody>
            <tr>
              <th scope="col" style={{ width: '20%' }}>
                Nome
              </th>
              <th scope="col" style={{ width: '15%' }}>
                Status
              </th>
              <th scope="col" style={{ width: '15%' }}>
                Ações
              </th>
            </tr>
            {links.map((s: any, index1: number) => (
              <tr key={index1}>
                <td>
                  <b>{s.name}</b>
                  <br />
                  <a href={`//${s.url}`} target="_blank">
                    {s.url}
                  </a>
                </td>
                <td>{s.active ? 'Ativa' : 'Inativa'}</td>

                <td>
                  <DropdownButton id="dropdown-basic-button" title="Ações">
                    <Dropdown.Item onClick={() => editSurvey(s)}>
                      Editar
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => enableDisableSurvey(s)}>
                      {!s.active ? 'Ativar' : 'Desativar'}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => deleteLink(s.id)}>
                      Remover
                    </Dropdown.Item>
                  </DropdownButton>
                </td>
              </tr>
            ))}
          </tbody>
        </S.Table>
      )}
    </S.Container>
  );
};

export default Links;
