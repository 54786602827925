import { createGlobalStyle, css } from 'styled-components';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/styles.scss';
import './fonts.css';
import { shade } from 'polished';

interface ContainerPros {
  customization: any;
}

export default createGlobalStyle<ContainerPros>`

:root{
  --max-width-large:1745px;
  --max-width-small:1200px;
  --fontFamily: 'Proxima Nova';
  --grid-gap-default: 16px;
  --border-radius-default: 10px;
}
body{
  font-family: var(--fontFamily);
}
*, h1, h2, h3, h4, h5, h6, p, a {
  font-family: var(--fontFamily);
}
.bg-body {
    ${(props) => css`
      padding: 0;
      /* background: linear-gradient(
        -45deg,
        ${props.customization?.backgroundHeaderGradient?.color2 ||
    props.customization?.backgroundHeaderGradientColor2}
          20%,
        ${props.customization?.backgroundHeaderGradient?.color1 ||
    props.customization?.backgroundHeaderGradientColor1}
          80%
      ); */
      /*Tirando a rolagem*/
      overflow-y: auto;
      overflow-x: hidden;
      min-height: 100vh !important;
    `}

}

.button_send_form
{
  ${(props) => css`
    background-color: ${props.customization.buttonBackgroundColor || '#000'};
    color: ${props.customization.buttonTextColor || '#FFF'};
    &:hover {
      background-color: ${shade(0.2, `${props.customization.buttonBackgroundColor || '#000'}`)};
    }
  `}
}

.button_login
{
  ${(props) => css`
    background-color: ${props.customization.buttonBackgroundColor || '#000'};
    color: ${props.customization.buttonTextColor || '#FFF'};
  `}

  height: 45px;
  border-radius: 40px;
  border: 0;
  padding: 0 5px;
  width: 100%;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s;

  &:hover {
    ${(props) => css`
      background-color: ${shade(
      0.2,
      props.customization.buttonBackgroundColor || '#000',
    )};
      color: ${props.customization.buttonTextColor || '#FFF'};
    `}
  }
}

#root {
  height: 100%;
  width: 100%;
  /* background-color: white; */
}

span.error {
  color: #bf1650;
  font-size:12px;
}

span.error::before {
  display: inline;
}

.link {
  padding: 5px;
}

.link,
.action-button{
  padding:5px
}
`;
