import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { Container } from './styles';
import { useWindowDimensions } from '../../hooks/useDimension';
import { useIsRetrato } from '../../context/contextplayer';
import Moderator from '../../components/Moderator';
import {
  getWidth,
  getDimensionWidth,
  getWidthRooms,
} from '../../function/DimensionsFunction';
import NavBar from '../../components/navbar';
import PlayerView from '../../components/PlayerView';

import DashboardMasterPage2 from '../../masterpages/DashboardMasterPage2';
import DashboardMasterPage from '../../masterpages/DashboardMasterPage';

import Banner from '../../components/Banner';
import TabMenuRight from '../../components/TabMenuRight';
import MenuTabRoom from '../../components/MenuTabRoom';

import { useAuth } from '../../hooks/Auth2';
import { useEvent } from '../../hooks/EventContext';
import { useTranslation } from 'react-i18next';
// import { usePresence, PresenceContext } from '../../hooks/PresenceContext';
import { useHistory, useParams } from 'react-router-dom';
import $ from 'jquery';
import Facelive from '../../components/FaceLive';
import PresenceUtils from '../../components/PresenceUtils';
import DashboardGrid from '../DashboardGrid';
import { useTheme } from '../../hooks/ThemeContext';

interface Resources {
  tab: boolean;
  name: string;
  key: string;
}

interface Data {
  description: string;
  eventdate: string;
  eventhour: string;
  name: string;
  resources: Resources[];
}
const DashBoard2: React.FC<any> = (props) => {
  const history = useHistory();
  const { event, channel } = useParams<{ channel: string; event: string }>();
  const { getUser, setUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();
  // const { registerPresence, getTotal, updatePresence, gap } = useContext(
  //   PresenceContext,
  // );
  // const { registerPresence, getTotal, updatePresence, gap } = usePresence();

  const { width, height } = useWindowDimensions();
  const [activeObj, setActiveObj] = useState<any>({}); //{ chat: true }
  const [audio, setAudio] = useState('');
  const [audioSettings, setAudioSettings] = useState<any[]>([]);
  const { t, i18n } = useTranslation();
  const { isRetrato } = useIsRetrato();
  const [channelModel, setChannelModel] = useState<any>('');
  const { theme } = useTheme();

  const DATA = eventContext.event;

  // useEffect(() => {
  //   function prevent(event: any) {
  //     console.log('body scroll', { event });
  //     // event.preventDefault();
  //     // window.scrollTo(0, 0);
  //     document.body.scrollTop = 0;
  //   }

  //   document.querySelector('body')?.addEventListener('scroll', prevent, false);
  //   setTimeout(() => {
  //     document
  //       .querySelector('body')
  //       ?.removeEventListener('scroll', prevent, false);
  //   }, 5000);
  // }, []);

  useEffect(() => {
    try {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    } catch (error) { }
  }, []);

  useEffect(() => {
    // console.log('event', eventContext?.event);
    // console.log('chanels', eventContext?.channels);
    // console.log('chanel', eventContext?.channel);
    // setChannelModel(eventContext?.channel?.customization?.channel_model || '');
  }, [eventContext]);

  function enableComponent(componentName: string) {
    const obj = { ...activeObj };
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = false;
      }
    }
    obj[componentName] = true;
    setActiveObj(obj);

    // $('.' + componentName + '-tab').removeClass('active');
  }

  useEffect(() => {
    if (eventContext?.channel?.resources?.length > 0) {
      enableComponent(eventContext?.channel?.resources[0].key);
    }

    if (!!user?.restrictChannels && eventContext?.channel?.key != undefined) {
      Object.entries(user?.restrictChannels).forEach((entry) => {
        const [keyy, value] = entry;

        if (eventContext?.channel?.key == keyy) {
          user.role = value;
          setUser(user);
        }
      });
    }

    setAudioSettings(eventContext?.channel?.audio_settings);
    setChannelModel(eventContext?.channel?.customization?.channel_model || '');

    $('#iframeBox')
      .contents()
      .find('img')
      .css({ width: '100% !important', height: '90% !important' });

    return () => { };
  }, [eventContext?.event, eventContext?.channel]);

  function toogleAudio() {
    audioSettings.map(function (data) {
      // console.log(data.default);
      data.default = !data.default;

      if (data?.default == false) {
        setAudio(data.url);
        getAudio();
      }
    });
  }

  function getAudio() {
    if (!audio) return eventContext?.channel?.streaming[i18n.language] || '';
    else return audio;
  }

  useEffect(() => {
    // let intervalTotal: any = null;
    // let intervalUpdate: any = null;
    // if (eventContext?.event?.id && eventContext.channel?.id) {
    //   registerPresence(eventContext.event.id, eventContext.channel.id);

    //   if (user?.isModerator) {
    //     getTotal(eventContext?.event.id, eventContext.channel.id); // force first load
    //     intervalTotal = setInterval(() => {
    //       // console.log('getTotal interval');
    //       getTotal(eventContext?.event?.id, eventContext?.channel.id);
    //     }, 1000 * 30);
    //   }

    //   intervalUpdate = setInterval(() => {
    //     // console.log('updatePresence interval');
    //     updatePresence(eventContext?.event?.id, eventContext?.channel?.id);
    //   }, gap * 0.9);
    // }

    setChannelModel(eventContext?.channel?.customization?.channel_model || '');

    $('#iframeBox')
      .contents()
      .find('img')
      .css({ width: '100% !important', height: '90% !important' });

    // return () => {
    //   if (intervalTotal) clearInterval(intervalTotal);
    //   if (intervalUpdate) clearInterval(intervalUpdate);
    //   // console.log('Page Dashboard render unmount');
    // };
  }, [eventContext.event?.id, eventContext?.channel?.id]);

  let toggle = false;
  // $("#ocultarToggle").click(function (event) {
  //   toggle = !toggle;

  //   if (toggle) {
  //     $("#player-container").hide('slow');
  //   }
  //   else {
  //     $("#player-container").show('slow');
  //   }

  //   return false;
  // });

  const maxWid =
    width <= 500
      ? width - 50
      : Number(width >= 1500 ? Number(1500 - 500) : width - 330);
  const largItem =
    width <= 500
      ? width - 50
      : Number(
        width <= 920
          ? Number(width - 150)
          : Number(width >= 1500 ? 1500 - 500 : width - 500),
      );
  const maxWid2 =
    width <= 500
      ? width - 50
      : Number(
        width <= 920
          ? width - 150
          : Number(width >= 1500 ? Number(1500 - 500) : width - 500),
      );
  const modoRetratoCSSmenuRoom: CSSProperties = {
    width: largItem,
    // background: '#707070',
  };
  const widthVideoSS =
    width <= 500
      ? width - 50
      : Number(
        width >= 1500
          ? width >= 1500 && width <= 1920
            ? Number(1500 - 150)
            : Number(1500 - 150)
          : width - 150,
      );

  if (user?.restrictChannels) {
    const userRestrictChannels = Object.keys(user?.restrictChannels);

    if (
      userRestrictChannels.findIndex((channelKey) => channelKey === channel) < 0
    ) {
      history.push(`/${event}`);
      return null;
    }
  }

  return (
    <>
      {!!channelModel && channelModel?.key == 'externallink' && (
        <DashboardMasterPage2 background={eventContext?.channel?.customization?.background} {...props} style={{ width: '100% !important' }}>
          <Container
            customization={eventContext?.channel?.customization || {}}
            style={{ width: '100% !important' }}
          >
            {/* {!!channelModel && channelModel?.key == 'externallink' && ( */}
            <iframe
              src={
                channelModel?.url +
                user?.id +
                '&skip=' +
                (user?.isModerator ? true : false)
              }
              style={{
                width: '100%',
                height:
                  eventContext?.channel?.type_room == 'eventShowRoom'
                    ? 'calc(100vh - 65px)'
                    : '100vh',
              }} /*Se tiver footer tem que ser - 176*/
            />
            {/* )} */}
          </Container>
        </DashboardMasterPage2>
      )}

      {(!channelModel || channelModel.key == '') && (
        <DashboardMasterPage2 background={eventContext?.channel?.customization?.background} {...props}>
          <DashboardGrid {...props} />
          {/*
          {width <= 920 && (
            <Container
              customization={eventContext?.channel?.customization || {}}
              style={{
                display: width <= 920 ? 'flex' : 'none',
                minHeight: height,
                alignItems: 'center',
                justifyItems: 'flex-start',
                background: eventContext?.channel?.customization?.background
                  ? `url(${eventContext?.channel?.customization.background})`
                  : eventContext?.event?.customization.backgroundColor ||
                    '#f5f8fd',
                backgroundSize: '100% 100%',
              }}
            >
              <div
                style={{
                  height:
                    width <= 500
                      ? Number(width) * 1.01
                      : Number(width / 2) * 1.75,
                  width: isRetrato ? width : widthVideoSS,
                  marginBottom: user?.isModerator ? '35px' : '',
                }}
                className="divrowplayerchat"
              >
                <PlayerView
                  hour={DATA?.eventhour}
                  date={DATA?.eventdate}
                  title={DATA?.name}
                  typeRoom={t(eventContext?.channel?.name) || t('Loading')}
                  typeRoom2={eventContext?.channel?.type_room || ''}
                  user={user}
                  isMobile={true}
                />
              </div>
              <TabMenuRight
                style={{ display: 'flex' }}
                height={Number(width / 2) * 3}
                width={widthVideoSS}
              />
              <div style={{ padding: 10 }} />
              <MenuTabRoom
                style={{
                  ...modoRetratoCSSmenuRoom,
                  display: 'flex',
                  width: widthVideoSS,
                  height: width + 70,
                }}
                restProps={props}
              />
            </Container>
          )}
         */}
        </DashboardMasterPage2>
      )}
      {eventContext?.event && user && (
        <PresenceUtils
          user={user}
          eventId={eventContext?.event?.id}
          channelId={eventContext?.channel?.id}
        />
      )}
    </>
  );
};
export default DashBoard2;
