import React, { useEffect } from 'react';
import { Container } from './styles';
import Image from 'react-bootstrap/Image'
import { useEvent } from '../../hooks/EventContext';
import FooterTerms from '../../components/FooterTerms';

const DefaultMasterPage: React.FC = (props: any) => {

  const eventContext = useEvent();

  useEffect(() => {
    eventContext.loadEvent(props.params.event);
  }, [props.params.event]);

  /*
   return (
      eventContext.event && (<Container id="login" className="container-fluid full-height" customization={eventContext.event?.customization || {}}>
          <div className="row full-height">
            <div id="image-bg" className="col-lg-9 col-md-6 align-self-start">
              <div className="logo-cliente">
               <Image className="imagem-logo" src={eventContext.event?.customization?.logo || ''} />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 align-self-center py-3">
              <div className="d-flex justify-content-center">
                <div className="form-s">
                  {props.children}
                </div>
              </div>
            </div>
          </div>
      </Container>)
    );
  */
  return (
    eventContext.event && (<Container id="login" customization={eventContext.event?.customization || {}}>
      <div className="image-bg">
        <div className='content-page-login'>
          <div className="bg_client">
            <Image className="" src={eventContext.event?.customization.banner} />
          </div>

          <div className={props.scheme || 'frm-default'}>
            {props.children}
          </div>
        </div>
        {
          eventContext.eventKey !== "volpecapital-convidado" && eventContext.eventKey !== 'volpecapital-investidor' ? (
            <FooterTerms />
          ): (
            ''
          )
        }
      </div>
    </Container>)
  );
};

export default DefaultMasterPage;
