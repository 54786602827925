import axios from 'axios';

const debug = 'http://localhost:3120';
const emulator = 'http://localhost:3333/rstcom20/us-central1/api';
const homologacao = 'https://us-central1-rstcom-sandbox.cloudfunctions.net/api';
const sandbox = 'https://sandbox-api.4yourlive.com';
const production = 'https://us-central1-rstcom20.cloudfunctions.net/api';
let url = production;

if (
  document.location.href.indexOf('homologacao.rstcom.stream') >= 0 ||
  document.location.href.indexOf('homologacaov3-rstcom') >= 0
) {
  url = homologacao;
  // if (document.location.href.indexOf('127.0.0.1') >= 0) url = debug;
} else if (
  document.location.href.indexOf('rstcom.crossdev.com.br') >= 0 ||
  document.location.href.indexOf('sandbox.4yourlive.com') >= 0 ||
  document.location.href.indexOf('rstcom-sandbox.firebaseapp.com') >= 0
) {
  url = sandbox;
  // if (document.location.href.indexOf('127.0.0.1') >= 0) url = debug;
} else if (document.location.href.indexOf(':6001') > -1) {
  url = homologacao;
} else if (document.location.href.indexOf('localhost') > -1) {
  // url = homologacao;
  // url = debug;
  url = sandbox;
} else if (document.location.href.indexOf('127.0.0.1') > -1) {
  url = production;
}

console.log(url);
const api = axios.create({
  baseURL: url,
});

export default api;
