import React from 'react';
import { useEvent } from '../../hooks/EventContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandshake,
  faHandHoldingUsd,
  faComments,
  faNetworkWired,
  faCog,
  faUserCircle,
  faCalendarAlt,
  faHome,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { MdPrivacyTip } from "react-icons/md";
import { VscTasklist } from "react-icons/vsc";
import { SidebarItem } from '../../types/SidebarItem';

export const SidebarData: SidebarItem[] = [
  {
    // MenuNavbar: true,
    title: 'Room',
    path: `/{{channel}}`,
    icon: <FontAwesomeIcon icon={faVideo} />,
    key: 'default',
  },
  {
    // MenuNavbar: true,
    title: 'Lobby',
    path: `/lobby`,
    icon: <FontAwesomeIcon icon={faHome} />,
    key: 'default',
  },
  {
    // MenuNavbar: true,
    title: 'Lobby 3D',
    path: `/{{channel}}`,
    icon: <FontAwesomeIcon icon={faHome} />,
    key: 'default',
  },
  {
    // MenuNavbar: true,
    title: 'Schedule',
    path: `/dashboard/schedule`,
    icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    key: 'schedule',
  },
  {
    // MenuNavbar: true,
    title: 'Speakers',
    path: `/dashboard/speakers`,
    icon: <FontAwesomeIcon icon={faComments} />,
    key: 'speakers',
  },
  {
    // MenuNavbar: true,
    title: 'Partners',
    path: `/dashboard/partners`,
    icon: <FontAwesomeIcon icon={faHandshake} />,
    key: 'partners',
  },
  {
    // MenuNavbar: true,
    title: 'Sponsors',
    path: `/dashboard/sponsors`,
    icon: <FontAwesomeIcon icon={faHandHoldingUsd} />,
    key: 'sponsors',
  },
  // {
  //   // MenuNavbar: true,
  //   title: 'Network',
  //   path: `/dashboard/network`,
  //   icon: <FontAwesomeIcon icon={faNetworkWired} />,
  // },
  {
    // MenuNavbar: true,
    title: 'Privacy',
    path: `/privacy-and-policy`,
    icon: <MdPrivacyTip size={20} color='#616161'/>,
    key: 'default',
  },
  {
    // MenuNavbar: true,
    title: 'Terms',
    path: `/terms-of-use`,
    icon: <VscTasklist size={20} color='#616161'/>,
    key: 'default',
  },
  {
    title: 'My Data',
    path: `/profile`,
    icon: <FontAwesomeIcon icon={faUserCircle} />,
    key: 'default',
  },
  // {
  //   title: 'Configurações',
  //   path: `/dashboard/settings`,
  //   icon: <FontAwesomeIcon icon={faCog} />,
  // },
  // {
  //   title: 'Configurações',
  //   path: `/dashboard/{{channel}}/settings`,
  //   icon: <FontAwesomeIcon icon={faCog} />,
  // },
];
