import React from 'react';

import { AuthProvider } from './Auth2';
import { ToastProvider } from './Toast';
import { I18Provider } from './i18';
import { EventProvider } from './EventContext';
import { FirebaseProvider } from './Firebase';
import { ChatProvider } from './ChatContext';
import { PresenceProvider } from './PresenceContext';
import { SpeakersProvider } from './SpeakersContext';
import {ThemeProvider} from './ThemeContext';

const AppProvider: React.FC = ({ children, ...rest }) => (
  <FirebaseProvider>
    <AuthProvider>
      <ToastProvider>
        <EventProvider {...rest}>
          <SpeakersProvider>
            <ThemeProvider>
              <I18Provider>{children}</I18Provider>
            </ThemeProvider>
          </SpeakersProvider>
        </EventProvider>
      </ToastProvider>
    </AuthProvider>
  </FirebaseProvider>
);

export default AppProvider;
