import React, {
  useCallback,
  useRef,
  ChangeEvent,
  useState,
  useEffect,
} from 'react';
import { FiCamera } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useEvent } from '../../hooks/EventContext';
import * as Yup from 'yup';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';
import { IUser } from '../../hooks/Auth2';
import { useToast } from '../../hooks/Toast';
//@ts-ignore
import AvatarIcon from '../../assets/images/avatarP.png';
import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';
import Input2 from '../../components/Input2';
import Button from '../../components/Button';

import * as S from './styles';
import { useAuth } from '../../hooks/Auth2';
import Header from '../../components/Header';
import DashboardMasterPage2 from '../../masterpages/DashboardMasterPage2';
import HeaderPageDescription from '../../components/HeaderPageDescription';
import { MainContainer2Column } from '../../components/Ui/MainContainer2Column';
import axios from 'axios';
import {useTheme} from '../../hooks/ThemeContext';
import { useTranslation } from 'react-i18next';
interface ProfileFormData {
  id?: string;
  name?: string;
  email: string;
  avatar?: string;
  eventKey: string;
  // oldPassword?: string;
  newPassword?: string;
  newPassword2?: string;
  crms?: string;
  crm?: string;
  crf?: string;
  fields: { UF: any; CRM: any; CRF: any, city?: string, phone?: string };
}

interface Province {
  sigla: string,
}

interface ProvinceCity {
  nome: string,
}

const ProfileGrid: React.FC<any> = (props) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { event } = useParams<{ event: string }>();
  const history = useHistory();
  const eventContext = useEvent();
  const {theme} = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { getUser, updateUser } = useAuth();
  const user = getUser();
  const [avatar, setAvatar] = useState<string | undefined>(user?.avatar);
  const [provinces, setProvinces] = useState<string[]>([]);
  const [selectedProvince, setSelectedProvince] = useState(user?.fields?.UF);
  const [cities, setCities] = useState<string[]>([]);
  const [selectedCity, setSelectedCity] = useState(user?.fields?.city);
  const [selectedCRMS, setSelectedCRMS] = useState(user?.fields?.CRF ? "CRF" : "CRM");
  const [selectedCRMSValue, setSelectedCRMSValue] = useState(user?.fields?.CRF ? user?.fields?.CRF : user?.fields?.CRM)
  const crms = [
    "CRM",
    "CRF",
  ]
  const [fields, setFields] = useState<{ [k: string]: unknown; }>({});

  useEffect(() => {
    if (selectedProvince === '0') {
      return;
    };
    axios.get<ProvinceCity[]>(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedProvince}/municipios`).then(response => {
      const cityNames = response.data.map(province => province.nome);
      setCities(cityNames);
    })
  }, [selectedProvince]);

  useEffect(() => {
    axios.get<Province[]>('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(response => {
        const provincesInitials = response.data.map(province => province.sigla);

        setProvinces(provincesInitials);
      })
    axios.get<ProvinceCity[]>(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${user?.fields?.UF}/municipios`).then(response => {
      const cityNames = response.data.map(province => province.nome);
      setCities(cityNames);
    })
  }, []);

  function handleSeletectedProvince(event: ChangeEvent<HTMLSelectElement>) {

    const selectedProvince = event.target.value;

    setSelectedProvince(selectedProvince);
  }
  function handleSeletectedCRMS(event: ChangeEvent<HTMLSelectElement>) {

    const selectedCRMSS = event.target.value;

    setSelectedCRMS(selectedCRMSS);

  }

  function handleSeletectedCity(event: ChangeEvent<HTMLSelectElement>) {
    const city = event.target.value;
    setSelectedCity(city);
  }

  function valuesToArray(obj: any) {
    const fill: Array<string> = [];
    Object.keys(obj).map((item) => fill.push(item));
    const items = Array.from(new Set(fill));
    return items;
  }

  useEffect(() => {
    if (user?.fields) {
      // const res = valuesToArray(user.fields);
        const fieldsObjectValues = Object.entries(user?.fields);
        const items = Object.fromEntries(fieldsObjectValues)
        setFields(items)
    }
  }, []);


  const handleSubmit = async (data: ProfileFormData) => {
    try {
      formRef.current?.setErrors({});

      if(!eventContext.event?.settings?.password){
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          // email: Yup.string().required('Required'),
          // surname: Yup.string().required('Sobrenome é obrigatório'),
          // phone: Yup.string().required('Telefone é obrigatório'),
          // city: Yup.string().required('Cidade é obrigatória'),
          // crm: Yup.string().required('CRM é obrigatório'),
          // crf: Yup.string().required('CRF é obrigatório'),
          password: Yup.string().required('Senha é obrigatória'),
        });
        if (loading) {
          return;
        }
        setLoading(true);

        if (!data.crm) {
          delete data.crm;
        }
        if (!data.crf) {
          delete data.crf;
        }

        const datas: ProfileFormData = {
          ...data,
          avatar: avatar,
          eventKey: event,
          id: user?.id,
          fields: {
          ...fields,
            UF: selectedProvince,
            CRM: data?.crm ? data?.crm : '',
            CRF: data?.crf ? data?.crf : '',
            city: selectedCity ? selectedCity: '',
            phone: data?.fields?.phone,
          },
        };

        if (user?.password) {
          // delete datas.oldPassword
          delete datas.newPassword;
          delete datas.newPassword2;
        }


        await schema.validate(datas, {
          abortEarly: false,
        });

        // const userr = await api.put(`/participant`, datas);
        const userr = await api.post('/admin/participant', datas);

        const userD: IUser = userr.data;
        updateUser({ ...user, ...userD });
      }

      else{
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          // email: Yup.string().required('Required'),
          // surname: Yup.string().required('Sobrenome é obrigatório'),
          // phone: Yup.string().required('Telefone é obrigatório'),
          // city: Yup.string().required('Cidade é obrigatória'),
          // crm: Yup.string().required('CRM é obrigatório'),
          // crf: Yup.string().required('CRF é obrigatório'),
        });
        if (loading) {
          return;
        }
        setLoading(true);

        if (!data.crm) {
          delete data.crm;
        }
        if (!data.crf) {
          delete data.crf;
        }

        const datas: ProfileFormData = {
          ...data,
          avatar: avatar,
          eventKey: event,
          id: user?.id,
          fields: {
          ...fields,
            UF: selectedProvince,
            CRM: data?.crm ? data?.crm : '',
            CRF: data?.crf ? data?.crf : '',
            city: selectedCity ? selectedCity: '',
            phone: data?.fields?.phone,
          },
        };

        if (user?.password) {
          // delete datas.oldPassword
          delete datas.newPassword;
          delete datas.newPassword2;
        }

        await schema.validate(datas, {
          abortEarly: false,
        });

        const userr = await api.post('/admin/participant', datas);

        // const userr = await api.put(`/participant`, datas);

        const userD: IUser = userr.data;
        updateUser({ ...user, ...userD });
      }

      addToast({
        type: 'success',
        title: `${t("Profile Updated")}`,
        description:
          `${t("Your profile information has been successfully updated!")}`,
      });
    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        return;
      }

      addToast({
        type: 'error',
        title: `${t("Update Error")}`,
        description: `${t("There was an error updating the profile, please try again")}.`,
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeImage = (e: any) => {
    e.preventDefault();
    const a: any = e.target.files;
    if (String(a[0].name).length !== 0) {
      var reader = new FileReader();
      var file = a[0];
      reader.onload = function (upload: any) {
        // console.log('imgem pageda', upload.target.result);
        setAvatar(String(upload.target.result));
      };
      reader.readAsDataURL(file);
    }
  };
  if (!user?.id) {
    return null;
  }

  return (
    <>
      <DashboardMasterPage2 {...props}>
        <MainContainer2Column>
          <S.Container>
            <HeaderPageDescription title={`${t("Edit Profile")}`}/>

            <S.Content>
              <S.ProfileContent theme={theme}>
                <S.FormProfile
                  ref={formRef}
                  initialData={{
                    name: user.name,
                    email: user.email,
                    avatar: user.avatar,
                    fields: user.fields,
                  }}
                  onSubmit={handleSubmit}
                  loading={loading}
                  theme={theme}
                >
                  <S.Avatarinput onChange={(e) => onChangeImage(e)}>
                    <img src={avatar || AvatarIcon} alt={user?.name || ''} />
                    <label htmlFor="avatar">
                      <FiCamera />
                      <input type="file" id="avatar"
                        disabled={!eventContext?.event?.resources.find((r:any) => r.key === 'profile')}
                        />
                    </label>
                  </S.Avatarinput>

                  <h1>{t("Configurations")}</h1>
                  <S.FormContainerInputs theme={theme}>
                    <S.InputBlock >
                      <label htmlFor="nome">{t("Name")}</label>
                      <Input2
                        name="name"
                        id="name"
                        placeholder="Nome"
                        disabled={!eventContext?.event?.resources.find((r:any) => r.key === 'profile')}
                      />
                    </S.InputBlock>
                    <S.InputBlock>
                      <label htmlFor="sobrenome">{t("Surname")}</label>
                      <Input2
                        name="surname"
                        defaultValue={user.surname}
                        id="surname"
                        placeholder="Sobrenome"
                        disabled={!eventContext?.event?.resources.find((r:any) => r.key === 'profile')}
                      />
                    </S.InputBlock>
                    <S.InputBlock>
                      <label htmlFor="telefone">{t("Telephone")}</label>
                      <Input2
                        name="fields[phone]"
                        defaultValue={user?.fields?.phone}
                        placeholder="55 (11) 000000000"
                        disabled={!eventContext?.event?.resources.find((r:any) => r.key === 'profile')}
                      />
                    </S.InputBlock>
                    <S.InputBlock>
                      <label htmlFor="email">{t("Email")}</label>
                      <Input2
                        name="email"
                        disabled
                        placeholder="E-mail"
                      />
                    </S.InputBlock>
                    <S.InputBlock>
                      <S.InputGroup2Columns>
                        <S.InputBlock>
                          <label htmlFor="cidade">{t("City")}</label>
                          <select name="city" id="city" value={selectedCity} defaultValue={user?.fields?.city} onChange={handleSeletectedCity}
                            disabled={!eventContext?.event?.resources.find((r:any) => r.key === 'profile')}
                        >
                            <option value={user?.fields?.city}>{user?.fields?.city ? user?.fields?.city : `${t("Select a city")}`}</option>
                            {cities.map(city => (
                              <option key={`profile-${city}`} value={city} defaultValue={user?.fields?.city}>{city}</option>
                            ))}
                          </select>
                        </S.InputBlock>

                        <S.InputBlock className='profile-form-input-small'>
                          <label htmlFor="uf" >UF</label>
                          <select name="province" id="province" value={selectedProvince} defaultValue={selectedProvince} onChange={handleSeletectedProvince}
                           disabled={!eventContext?.event?.resources.find((r:any) => r.key === 'profile')}
                        >
                            <option value={user?.fields?.UF}>{user?.fields?.UF ? user?.fields?.UF : `${t("Select a state")}`}</option>
                            {provinces.map(province => (
                              <option key={`profile-${province}`} value={province} defaultValue={user?.fields?.UF}>{province}</option>
                            ))}
                          </select>
                        </S.InputBlock>
                      </S.InputGroup2Columns>
                    </S.InputBlock>
                    {!eventContext.event?.settings?.password && (
                       <S.InputBlock>
                       <label htmlFor="senha">{t("Password")}</label>
                       <Input2
                         name="password"
                         type="password"
                         defaultValue={user.password}
                         placeholder="Senha"
                         disabled={!eventContext?.event?.resources.find((r:any) => r.key === 'profile-password')}
                         />
                     </S.InputBlock>
                    )}
                    <S.InputBlock>
                      <label htmlFor="cidade">{t("Select your document identification")}:</label>
                      <S.InputGroup2Columns>
                        <S.InputBlock className='profile-form-input-small'>
                          <select name="crms" id="crms" value={selectedCRMS} onChange={handleSeletectedCRMS} disabled>
                            {crms.map((cr, index) => (

                              <option key={`profile-typeDoc-${index}`} value={cr} >{cr}</option>

                            ))}
                          </select>
                        </S.InputBlock>
                        <Input2
                          name={selectedCRMS === "CRM" ? "crm" : "crf"}
                          value={selectedCRMSValue}
                          onChange={(e) => setSelectedCRMSValue(e.target.value)}
                          placeholder="0000000-0/BR"
                          disabled
                        />
                      </S.InputGroup2Columns>
                    </S.InputBlock>
                  </S.FormContainerInputs>
                  <S.ContainerButtons>
                    <Button
                      color={eventContext.event?.customization?.buttonTextColor}
                      background='#02C90F'
                      type="submit"
                      loading={loading}
                    // loadingText={'Atualizando...'}
                    >
                      {/* {loading ? 'Atualizando...' : 'Atualizar perfil'} */}
                      {t("Update profile")}
                    </Button>
                    <Button
                      background='#c5c5c5'
                      color={eventContext.event?.customization?.buttonTextColor}
                      onClick={() => history.goBack()}
                      disabled={loading}
                    >
                      {t("Cancel")}
                    </Button>
                  </S.ContainerButtons>

                </S.FormProfile>

              </S.ProfileContent>
            </S.Content>
          </S.Container>
        </MainContainer2Column>
      </DashboardMasterPage2>
    </>
  );
};

export default ProfileGrid;
