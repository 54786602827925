import React, { useState, useEffect } from 'react';
import { useEvent } from '../../hooks/EventContext';
import { useTheme } from '../../hooks/ThemeContext';
import * as S from './style';
import * as firebase from 'firebase/app';

import 'firebase/firestore';
import HeaderPageDescription from '../HeaderPageDescription';
import { useTranslation } from 'react-i18next';

const firestore = firebase.firestore();

const PartnersGrid: React.FC = (props: any) => {
  const eventContext = useEvent();
  const { theme } = useTheme();
  const [partners, setPartners] = useState<
    { id: string; name: string; link: string; cover: string }[]
  >([]);
  const { t } = useTranslation();
  // const sponsors = [
  //   {
  //     cover:
  //       'https://firebasestorage.googleapis.com/v0/b/rstcom20.appspot.com/o/V3%2Fpartners2%2Flogo01.png?alt=media&token=520102e9-00c2-4dac-b536-e07745dd3d90',
  //     name: 'iFC',
  //     link: 'https://www.google.com/',
  //   },
  // ];

  useEffect(() => {
    firestore
      .collection('partner')
      .where('eventId', '==', eventContext.event.id)
      .onSnapshot((snap) => {
        const partners = snap.docs?.map<{
          id: string;
          name: string;
          link: string;
          cover: string;
        }>((item) => ({
          ...(item.data() as any),
          id: item.id,
        }));
        setPartners(partners);
      });
  }, []);

  return (
    <S.Container>
      <HeaderPageDescription
        title={`${t("Partners")}`}
        // description={`Lorem Ipsum is simply dummy text of the printing and typesetting industry.`}
      />
      <S.PartnersContainerItems>
        {partners.map((partners, index) => (
          <S.PartnersItem
            delay={index * 0.0125}
            key={`partners-item-${partners.name}-${index}`}
            theme={theme}
          >
            <abbr title={partners.name}>
              <a href={partners?.link} target="_blank">
                <img src={partners?.cover} alt={partners.name} />
              </a>
            </abbr>
          </S.PartnersItem>
        ))}
      </S.PartnersContainerItems>
    </S.Container>
  );
};
export default PartnersGrid;
