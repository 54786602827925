import React, { useEffect, useState, CSSProperties } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';

import VideoView from '../../components/VideoView';
import Header2 from '../../components/Header2';

import './styles.css';

import SideTabMenu from '../../components/SideTabMenu';
import TabsMenu from '../../components/PlayerView/TabsMenu';
import { useEvent } from '../../hooks/EventContext';
import { useAuth } from '../../hooks/Auth2';
import { MenuDropdownAudio } from '../../components/MenuDropdownAudio';
import { Icon } from '../../components/Icons';

import NewsFeatures from '../../components/NewsFeatures/index';
import Footer from '../../components/Footer';
import FooterTerms from '../../components/FooterTerms';
import HeaderTitle from '../../components/HeaderTitle';
import Header from '../../components/Header';
import { BiPlay } from 'react-icons/bi';
import { usePresence } from '../../hooks/PresenceContext';
import UsersCountComponent from '../../components/Features/UsersCount';
import { useIndexTabPlayerView, useIsRetrato } from '../../context/contextplayer';
import useWindowDimensions from '../../hooks/useDimension';
import FaceLive from '../../components/FaceLive';
import Survey from '../../components/Moderator/Survey';
import Questions from '../../components/Moderator/Questions';
import Links from '../../components/Moderator/Links';
import Users from '../../components/Moderator/Users';
import Attendance from '../../components/Moderator/Attendance';
import ChatModeration from '../../components/Moderator/ChatModeration';
import { useTheme } from '../../hooks/ThemeContext';
import { Button, FeatureDown } from './style';
import { asEnumerable } from 'linq-es2015';
import UsersOnlineCount from '../../components/NewsFeatures/UsersOnlineCount';

interface EntityBodyLANG {
  id: string;
  flag: string;
  label: string;
  language: string;
  default: boolean;
  urlAudio: string;
}

const DashboardGrid: React.FC = (props: any) => {
  const { getUser } = useAuth();
  const [theaterMode, setTheaterMode] = useState(false);
  const firestore = firebase.firestore();
  const user = getUser();
  const { toggleTheme, theme } = useTheme();
  const { event: eventKey, channel: channelKey } =
    useParams<{ channel: string; event: string }>();
  const { t, i18n } = useTranslation();
  const { event, channel, key, setKey, ...eventContext } = useEvent();
  let preferenceAudios: string = localStorage.getItem('@preferenceAudio') || '';
  const setPreferenceAudio = (audio: string) => {
    localStorage.setItem('@preferenceAudio', audio);
    setPreferenceAudios(audio);
  };
  const [preferenceAudio, setPreferenceAudios] =
    useState<string>(preferenceAudios);

  const { indexTabPlayerView } = useIndexTabPlayerView();

  useEffect(() => {
    console.log('DashboardGrid useEffect', { eventKey, channelKey });

    if (eventKey && channelKey) eventContext.loadInfo(eventKey, channelKey);
  }, [eventKey, channelKey]);

  console.log('DashboardGrid', { event, channel });

  useEffect(() => {
    const pre: string = localStorage.getItem('@preferenceAudio') || '';
    if (channel?.voiceSettings) {
      const daLang: EntityBodyLANG[] = channel?.voiceSettings;
      if (daLang.findIndex(e => e.id === pre) >= 0) {

      } else {
        setPreferenceAudios('')
      }
    }
  }, [channel]);

  function like(channel: any) {
    if (!user) return;
    const ref = firestore.collection('channel').doc(channel.id);
    channel.likesObj = channel.likesObj || {};
    if (!channel.likesObj[user.id]) {

      channel.likesObj = { ...channel.likesObj, [user.id]: true };
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(1),
        likesObj: channel.likesObj,
      });
    } else {
      delete channel.likesObj[user.id];
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(-1),
        likesObj: channel.likesObj,
      });
    }
  }

  const getStyleTabItem = (index: number) => {
    const style: CSSProperties = {
      display: indexTabPlayerView === index ? 'flex' : 'none',
      width: '100%',
      height: '100%',
      overflow: 'scroll',
      // background: '#f7f7f7',
      justifyContent: 'center',
      padding: 16,
    };
    return style;
  };

  return (
      <div className={(theaterMode || !key) ? "main-theater" : "main"}>
      <div className={(theaterMode || !key) ? "player-container-theater" : "player-container"}>
        <div className="child1">
          <HeaderTitle theaterMode={!key ? true : theaterMode} eventRoom={channel?.name}  eventDate={channel?.channel_date}/>
          {user?.isModerator && <TabsMenu />}
          <div
            className="videoview"
          >
            <div
              style={{
                ...getStyleTabItem(0),
                // background: 'black',
                overflow: 'none',
                alignItems: 'center',
                padding: 0,
              }}
            >
              {!!props?.typeRoom2 && props?.typeRoom2 == 'eventFacelive' && (
                <FaceLive user={user}></FaceLive>
              )}

              <VideoView
                preferenceAudioId={preferenceAudio}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
            {user?.role !== 'speaker' && (
              <div className="bg-white overflow-auto" style={getStyleTabItem(1)}>
                <Survey
                  channelId={channel?.id}
                  eventId={event?.id}
                  user
                />
              </div>
            )}

            <div className="bg-white overflow-auto" style={getStyleTabItem(2)}>
              <Questions
                channelId={channel?.id}
                eventId={event?.id}
                user
              />
            </div>

            <div className="bg-white overflow-auto" style={getStyleTabItem(3)}>
              <Links
                channelId={channel?.id}
                eventId={event?.id}
                user
              />
            </div>
            {user?.role !== 'speaker' && (
              <div className="bg-white overflow-auto" style={getStyleTabItem(4)}>
                <Users
                  channelId={channel?.id}
                  eventId={event?.id}
                  user
                />
              </div>
            )}

            {user?.isAttendance && (
              <div className="bg-white overflow-auto" style={getStyleTabItem(5)}>
                <Attendance {...(props as any)} />
              </div>
            )}
            {user?.isModerator && (
              <div className="bg-white overflow-auto" style={getStyleTabItem(6)}>
                <ChatModeration {...(props as any)} />
              </div>
            )}
          </div>

          <FeatureDown theme={theme} theaterMode={!key ? true : theaterMode}>
            <div className="video-info">
            {asEnumerable(event?.resources || []).Any(
                (r: any) => r.key == 'online',
              ) && (user?.isModerator || user?.isAttendance) &&
              <button
                id="countdown-people"
                className="countdown-action countdown-people"
                type="button"
              >
                <Icon name="iconPeople" />
                {/* {!!channel?.resources && (
                  <UsersOnlineCount
                  eventId={event?.id}
                  channelId={channel.id}
                  channelName={channel.name}
                  />
                )} */}
               <UsersCountComponent/>
              </button>
              }
              <button
                id="countdown-like"
                className="countdown-action countdown-like"
                type="button"
                onClick={() => like(channel)}
              >
                <Icon name="iconHeart" />
                <span>{channel?.likes || 0}</span>
              </button>
            </div>

            <div className="actions">
              <MenuDropdownAudio
                preferenceAudio={preferenceAudio}
                setPreferenceAudio={setPreferenceAudio}
              />
              <div className={theaterMode ? 'theater-left-stick' : 'left-stick'}></div>
              {key ? (
                <div onClick={() => setTheaterMode(!theaterMode)} className={theaterMode ? 'theater-mode' : 'normal-mode'}>
                 <BiPlay color='rgba(0, 0, 0, 0.2)' size={10} />
                </div>
              ) : (
                <div className={theaterMode ? 'theater-mode' : 'normal-mode'}>
                 <BiPlay color='rgba(0, 0, 0, 0.2)' size={10} />
                </div>
              )}
              <div className={theaterMode ? 'theater-right-stick' : 'right-stick'}></div>
              {/* <Button theme={theme} onClick={() => toggleTheme()}><Icon name='iconDarkMode' /></Button> */}
            </div>
          </FeatureDown>
        </div>
        {!theaterMode && (
          <div className="features">
            <SideTabMenu />
          </div>
        )}
      </div>

      <div style={{ display: theaterMode ? 'flex' : 'block', flexDirection: theaterMode ? 'row' : 'column' }}>
        {theaterMode && (
          <div className='chat'>
            <SideTabMenu
              theaterMode={theaterMode}
            />
          </div>
        )}

    {!!channel?.resources && (
      <NewsFeatures
        customization={event?.customization || {}}
        user={user}
        eventId={event?.id}
        channelId={channel?.id}
        group={channel?.customization?.channel_group}
        {...props}
      />
    )}
      </div>
    </div>
  );
};
export default DashboardGrid;
