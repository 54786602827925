import React, { useEffect, useMemo, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useTranslation } from 'react-i18next';

import { useEvent } from '../../hooks/EventContext';

import './ScheduleContainer.css';
import { useSpeaker } from '../../hooks/SpeakersContext';
import { asEnumerable } from 'linq-es2015';

import * as S from './style'
import { Icon } from '../Icons';
import api from '../../services/api';
import HeaderPageDescription from '../HeaderPageDescription';
import { useTheme } from '../../hooks/ThemeContext';
interface Speakerinfor {
  hour?: string;
  name?: string;
  picture?: string;
  profession?: {
    pt: string;
    en: string;
    es: string;
  };
  description?: {
    pt: string;
    en: string;
    es: string;
  };
}
interface InfoSchedule {
  id?: string;
  day?: string;
  date?: string;
  speakers?: Speakerinfor[];
  description?: {
    pt: string;
    en: string;
    es: string;
  };
  start: number;
}

interface Agenda {
  id: string;
  day: string;
  date: string;
  hour: string;
  profission: string;
  name: string;
  descriptionH3: string;
  descriptionP: string;
  orderNumber: number;
  eventKey: string;
  eventId: string;
  speaker: Speak;
}

interface Speak {
  id: string;
  name: string;
  picture: string;
  description: any;
  profession: any;
}

const ScheduleContainer: React.FC = () => {
  const { speakers: $speakers } = useSpeaker();
  const [speakers, setSpeakers] = useState<any>({});
  const firestore = firebase.firestore();
  const [schedule, setSchedule] = useState<any>({
    programation: [],
    programation_language: [],
  });
  const { channel, event, ...eventContext } = useEvent();
  const [datas, setDatas] = useState<Array<InfoSchedule>>([]);
  const [visibleTab, setVisibleTab] = useState(0);
  const [agenda, setAgenda] = useState<Agenda[]>([])
  const [agendaFiltered, setAgendaFiltered] = useState<Agenda[]>([])
  const [days, setDays] = useState<any[]>([])
  const [dates, setDates] = useState<any[]>([])
  const [isBusy, setIsBusy] = useState<boolean>(true)
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  useEffect(() => {
    const speakerObj: any = {};

    if (!$speakers?.length) return;

    $speakers.map((speaker: any) => {
      speakerObj[speaker.id] = speaker;
      return speaker;
    });
    setSpeakers(speakerObj);
  }, [$speakers]);

  useEffect(() => {
    if (channel?.id) {
      const unsubscribe = firestore
        .collection('schedule')
        .doc(channel.id)
        .onSnapshot((snapshot) => {
          const schedule: any = snapshot.data() || {};
          schedule.programation_language = asEnumerable(
            schedule.programation_language || [],
          )
            .Where((u: any) => u.status == 'actived' || u.status == 'enabled')
            .ToArray();
          const programation: any = asEnumerable(
            schedule.programation_language,
          ).FirstOrDefault((a: any) => a.status == 'actived');
          setSchedule(schedule);

          eventContext.setProgramation(programation);
          setDatas(schedule?.programation_language);
          if (programation?.speakers?.length) {
            eventContext.setSpeakers(
              programation?.speakers || eventContext.speakers,
            );
          } else {
            let speakers: any[] = [];
            asEnumerable(schedule.programation_language)
              .Select((item: any) => {
                speakers = asEnumerable(speakers)
                  .Union(asEnumerable(item.speakers || []))
                  .Select((speaker: any) => {
                    return {
                      id: speaker.id,
                      name: speaker.name,
                    } as any;
                  })
                  .Distinct()
                  .ToArray();
              })
              .ToArray();
            eventContext.setSpeakers(
              speakers.length ? speakers : eventContext.speakers,
            );
          }
        });
      return () => {
        unsubscribe();
      };
    }
  }, [channel?.id]);

  const loadAgenda = () => {
    if (!event.id) return;
    api.get(`/agenda/${event.id}`).then((r) => {
      let daysArray = Array.from(new Set(r.data.map((value: any) => value.day))).map((value) => {
        return {
          day: value
        }
      })
      let datesArray = Array.from(new Set(r.data.map((value: any) => value.date))).map((value) => {
        return {
          date: value
        }
      })

      setDates(datesArray)
      setDays(daysArray)

      setVisibleTab(Number(daysArray[0]?.day))
      setAgenda(r.data);
    }).finally(() => setIsBusy(false))
  }

  useEffect(() => {
    if (event?.id) {
      loadAgenda()
    }
  }, [event?.id])

  useEffect(() => {
    if (!visibleTab && isBusy) return;
    setAgendaFiltered(() => {
      return agenda.filter(item => +item.day === visibleTab)
    })
  }, [visibleTab, isBusy])

  return (
    <S.Container>
      <HeaderPageDescription title={`${t("Schedule")}`}>
        <S.ContainerButtonSetDate>
          {days.map((schedule, index) => {
            return (
              <S.ButtonSetDateShedule
                key={index}
                type="button"
                onClick={() => setVisibleTab(Number(schedule.day))}
                isActive={visibleTab === Number(schedule.day)}
                customization={event?.customization || {}}
              >
                {`${t("Day")} ${(schedule.day).padStart(2, '0')}`}
                {dates[index].date && ` - ${dates[index].date}`}
              </S.ButtonSetDateShedule>
            )
          })}
        </S.ContainerButtonSetDate>
      </HeaderPageDescription>
      <S.ContainerSchedule>
        <S.CardScheduleGroup>
          {!isBusy &&
            agendaFiltered?.map((item: Agenda, index: number) => (
              <S.CardSchedule key={`card-schedule-item-speaker-${item?.speaker?.name}`} delay={index * .025} theme={theme}>
                <S.CardDate theme={theme}>
                  <Icon name='clock' />
                  <span className='shedule-card-item-hour'>{item.hour || "00h00"} </span>
                </S.CardDate>
                <S.CardSpeakerImage>
                  {item?.speaker?.picture && <img src={item?.speaker?.picture} alt={item.speaker?.name || ''} />}
                </S.CardSpeakerImage>
                <S.CardContent>
                  <S.CardHeader>
                    <S.CardTitle theme={theme}>{item.speaker?.name}</S.CardTitle>
                    {item.speaker?.profession?.[i18n.language] && <S.CardProfission>{item.speaker?.profession?.[i18n.language] || ''}</S.CardProfission>}
                  </S.CardHeader>
                  <b>{item?.descriptionH3 && <S.CardText theme={theme} dangerouslySetInnerHTML={{ __html: item?.descriptionH3 || '' }} />}</b>
                  {item?.descriptionP && <S.CardText theme={theme} dangerouslySetInnerHTML={{ __html: item?.descriptionP || '' }} />}
                </S.CardContent>
              </S.CardSchedule>
            ))
          }
        </S.CardScheduleGroup>
      </S.ContainerSchedule>
    </S.Container>
  );
};

export default ScheduleContainer;

