import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import { useEvent } from '../../hooks/EventContext';
import { useTranslation } from 'react-i18next';

import ReactFlagsSelect from 'react-flags-select';
interface DataProps {
  data: any;
}

const countriesByLang: any = { pt: 'BR', en: 'US', es: 'ES' };
const labelsByCountrie: any = { BR: 'PT-BR', US: 'EN-US', ES: 'ES' };
const langByCode: any = { BR: 'pt', US: 'en', ES: 'es' };

const Languages: React.FC<DataProps> = (props: any) => {
  const [selected, setSelected] = useState('');
  const [languages, setLanguages] = useState<{
    countries: any[];
    customLabels: any;
  }>({ countries: [], customLabels: {} });
  const onSelect = (code: string): void => setSelected(code);

  const eventContext = useEvent();
  const { t, i18n } = useTranslation();

  function setLanguage(language: string) {
    if (!language || language === 'undefined') return;
    console.log('setLanguage', { language, valid: !language });
    setSelected(language);
    i18n.changeLanguage(langByCode[language]);
    localStorage.setItem('defaultLanguage', langByCode[language]);
  }

  useEffect(() => {
    if (!eventContext.event?.settings?.languages) return;

    let countries: any[] = [];
    let customLabels: any = {};

    eventContext.event?.settings?.languages.map(({ language }: any) => {
      let country = countriesByLang[language];
      countries.push(country);
      customLabels[country] = language.toUpperCase();
    });
    // countries.map((country) => {
    //   customLabels[country] = labelsByCountrie[country];
    // });


    setLanguages({ countries, customLabels });

    if (i18n.language) setLanguage(countriesByLang[i18n.language]);
    // setTimeout(() => {
    //   setLanguage(i18n.language);
    // }, [2000]);
  }, []);

  useEffect(() => {
    //
  }, [i18n]);



  return (
    <Conteiner>
      <main
        className="container"
      >
        <ReactFlagsSelect
          fullWidth
          selected={selected}
          onSelect={setLanguage}
          countries={languages.countries}
          customLabels={languages.customLabels}
          // countries={['BR', 'US', 'GB']}
          // customLabels={{ BR: 'PT-BR', US: 'EN-US', GB: 'EN-GB' }}
          placeholder={t('Language')}
        />
      </main>
    </Conteiner>
  );
};

export default Languages;
