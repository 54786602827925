import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import * as firebase from 'firebase/app';

import 'firebase/firestore';

const ChatContext = createContext<ChatData>({} as ChatData);

export interface ChatData {
  chat: any;
  isLoading: boolean;
  loadChat(
    channelId: string,
    firestore: firebase.firestore.Firestore,
  ): Promise<void>;
}

const ChatProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [channelId, setChannelId] = useState<string>();
  const [chat, setChat] = useState<any>(null);

  async function loadChat(
    $channelId: string,
    firestore: firebase.firestore.Firestore,
  ): Promise<void> {
    try {
      if (channelId === $channelId) return;

      console.log('***** ChatContext LoadChat *****');

      setChat(null);

      setChannelId($channelId);

      // const result = await api.get(`/chat?channelId=${$channelId}`);
      const $user = firebase.auth()?.currentUser;
      console.log('user signed', {
        user: $user,
        email: $user?.email,
        verified: $user?.emailVerified,
      });

      const docSnap = await firestore.collection('chat').doc($channelId);
      const channelDoc = await firestore
        .collection('channel')
        .doc($channelId)
        .get();
      const channel = channelDoc.data();

      let $chat = null;

      const data = {
        id: $channelId,
        channelId: $channelId,
        eventId: channel?.eventId,
        createdAt: moment.utc().valueOf(),
        count: 0,
        messages: [],
      };

      try {
        let query = await docSnap.get();
        $chat = query?.data();

        if (!$chat) {
          await firebase.firestore().doc($channelId).set(data);

          query = await docSnap.get();
          $chat = query.data();
        }
      } catch (error) {
        if (!$chat) {
          await firestore.collection('chat').doc($channelId).set(data);
          const query = await docSnap.get();
          $chat = query.data();
        }
      }

      if ($chat) setChat($chat);
      console.log('loadChat', { data: $chat });
    } catch (error) {
      console.log('loadChat error', { error, channelId });
    }
  }

  useEffect(() => {
    if (channelId) {
      const unsubscrible = firebase
        .firestore()
        .collection('chat')
        .doc(channelId)
        .onSnapshot((snapshot) => {
          console.log('chat changed');

          const data = snapshot.data();
          setChat(data);
        });

      return () => {
        unsubscrible();
      };
    }
  }, [channelId]);

  return (
    <>
      <ChatContext.Provider
        value={{
          chat,
          loadChat,
          isLoading,
        }}
      >
        {children}
      </ChatContext.Provider>
    </>
  );
};

function useChat(): ChatData {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be used within an ChatProvider');
  }

  return context;
}

export { ChatProvider, useChat };
