import React, { useEffect } from 'react';
import {
  Route as ReactDomRoute,
  RouteProps as ReactDomRouteProps,
  Redirect,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import { useAuth } from '../hooks/Auth2';
import { useEvent } from '../hooks/EventContext';

interface RouteProps extends ReactDomRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<any> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const math = useRouteMatch();
  const { event } = rest?.computedMatch?.params || {};
  const { getUser } = useAuth();
  const eventContext = useEvent();
  const user = getUser();

  // useEffect(() => {
  //   if (user && user.eventKey !== event) {
  //     alert('Acesso não autorizado');
  //   }
  // }, []);

  console.log('Route component render', {
    url: event?.settings?.redirect?.url,
    user,
    event,
    math,
    rest,
  });

  // if (
  //   isPrivate &&
  //   eventContext?.event?.settings?.redirect?.enabled &&
  //   eventContext?.event?.settings?.redirect?.url
  // ) {
  //   // window.location.href = event?.settings?.redirect?.url;
  //   return (
  //     <ReactDomRoute
  //       {...rest}
  //       component={() => {
  //         window.location.href = eventContext?.event?.settings?.redirect?.url;
  //         return null;
  //       }}
  //     />
  //   );
  // }

  return (
    <ReactDomRoute
      {...rest}
      render={() => {
        return isPrivate && (!user || (user && user.eventKey !== event)) ? (
          <Redirect to={`/${rest.computedMatch.params.event}/signin`} />
        ) : (
          <Component {...rest} {...rest.computedMatch} />
        );
      }}
    />
  );
};

export default Route;
