export class LoginUtils {
  static key = '@rst:attemps';
  static attemps_limit = 3;

  static increaseAttemps() {
    console.log('increaseAttemps');
    let _attemps = this.getAttemps();
    _attemps += 1;

    sessionStorage.setItem(this.key, _attemps.toString());
  }

  static getAttemps() {
    const _attemps_str = sessionStorage.getItem(this.key) || '0';

    console.log('getAttemps', _attemps_str);

    return Number(_attemps_str);
  }

  static clearAttemps() {
    sessionStorage.setItem(this.key, '0');
  }

  static attempsExceeded() {
    const _attemps = this.getAttemps();

    return _attemps >= this.attemps_limit;
  }
}
