import React, { useRef, useEffect, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import { useEvent } from '../../../hooks/EventContext';
import { asEnumerable } from 'linq-es2015';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';
import Emitter from '../../../utils/Event';
import UsersOnlineCount from '../UsersOnlineCount';
import { useAuth } from '../../../hooks/Auth2';
import UsersCountComponent from '../../Features/UsersCount';
import { Icon } from '../../Icons';
import * as S from './styles';
import { useTheme } from '../../../hooks/ThemeContext';

const CardSlideChannel: React.FC = (props: any) => {
  const { event, channel: channelKey } = useParams<{
    channel: string;
    event: string;
  }>();
  const ref = useRef(null);
  const firestore = firebase.firestore();
  const { getUser } = useAuth();
  const user = getUser();
  const { group } = props;
  const { t, i18n } = useTranslation();
  const [channels, setChannels] = useState<any[]>([]);
  const eventContext = useEvent();
  const { theme } = useTheme();

  const { onMouseDown } = useDraggableScroll(ref, {
    direction: 'horizontal',
  });

  useEffect(() => {
    const docs = eventContext.channels;

    const channel = asEnumerable(docs).FirstOrDefault(
      (c: any) => c.id === props.channelId,
    );

    const result = asEnumerable(docs)
      .Where((c: any) => {
        return (
          (!!channelKey || !(c.showlobby === false)) && // if channelKey not exists and showlobby equals to false hide channel
          ((!group && !c?.customization?.channel_group) ||
            c.customization?.channel_group?.trim() === group)
        );
      })
      .OrderBy((u: any) => u.order)
      .ToArray();

    setChannels(result);

    if (channel) {
      eventContext.setChannel(channel);
    }
  }, [eventContext.channels, group]);

  function like(channel: any) {
    if (!user) return;
    const ref = firestore.collection('channel').doc(channel.id);
    channel.likesObj = channel.likesObj || {};
    if (!channel.likesObj[user.id]) {
      channel.likesObj = { ...channel.likesObj, [user.id]: true };
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(1),
        likesObj: channel.likesObj,
      });
    } else {
      delete channel.likesObj[user.id];
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(-1),
        likesObj: channel.likesObj,
      });
    }
  }

  console.log('CardSlideChannel channels', {
    channels,
    ev_channels: eventContext.channels,
  });

  function HeaderView() {
    let location = useLocation();
    let splt = location.pathname.split('/');

    if (splt[2] == 'lobby' || splt[2] == 'stand3d') {
      //setActivePage(splt[2]);
      return true;
    } else return false;
  }

  const hasHeaderView = HeaderView();

  return (
    <>
      {hasHeaderView ? (
        <S.Container ref={ref} onMouseDown={onMouseDown}>
          {channels.map((channel: any, i: number) =>
            (!props.params.channel ||
              channel.id != eventContext.channel.id) &&
              channel?.key != 'stand3d' ? (
               <React.Fragment key={i}>
              {!!channel?.type ||
                (channel.type != 'videoteca' &&
                  !!user?.restrictChannels &&
                  Object.keys(user?.restrictChannels)?.findIndex(
                    (channelKey) =>
                      channelKey?.trim() === channel?.key?.trim(),
                  ) >= 0 && (
                    <S.Card theme={theme}>
                    <Link
                      key={i}
                      to={{
                        pathname: '/' + props.params?.event + '/dashboard/' + channel.key,
                        key: String(new Date().getTime()),
                      }}
                    >
                      <S.CardImageContainer>
                        <S.CardImage alt="400x220" src={channel?.picture} />

                        <S.CardImageIcons>
                          {/* <Icon name="iconLock" /> */}
                          <span></span>
                          {/* <div>
                            <span className="card-channel-icon-live">NO AR</span>
                            <Icon name="iconEye" />
                          </div> */}
                        </S.CardImageIcons>
                      </S.CardImageContainer>
                    </Link>
                    <S.CardContent theme={theme}>
                      <S.CardInfo>
                        <S.CardSpeakers>
                          {channel?.speakers.map((speakerImage: any) => (
                            speakerImage?.picture &&
                              <img alt="44x44" src={speakerImage?.picture} />
                          ))}
                        </S.CardSpeakers>
                        <S.CardInfoHeader>
                          <S.CardTitle theme={theme}>{channel?.name}</S.CardTitle>
                          <S.CardTitle theme={theme}>
                            {channel?.hour && `${channel?.hour}`}
                          </S.CardTitle>
                        </S.CardInfoHeader>
                        <S.CardInfoText theme={theme}>
                          {channel?.description_languages[i18n.language]}
                        </S.CardInfoText>
                      </S.CardInfo>
                      <S.CardFooter theme={theme}>
                        <S.ContainerIcons>
                        {asEnumerable(eventContext.event?.resources || []).Any(
                          (r: any) => r.key == 'online',
                        ) && (user?.isModerator || user?.isAttendance) &&
                          <button className="card-channels-item-icon icon-people">
                            <Icon name="iconPeople" />
                            <UsersOnlineCount
                              eventId={props.eventId}
                              channelId={channel.id}
                              channelName={channel.name}
                            />
                        </button>
                        }
                          <button
                            className="card-channels-item-icon icon-heart"
                            onClick={() => like(channel)}
                          >
                            <Icon name="iconHeart" />
                            <span>{channel?.likes || 0}</span>
                          </button>
                        </S.ContainerIcons>

                        <S.CardButtonAccess href={`/${props.params?.event}/dashboard/${channel.key}`}
                        >
                          {t("Access")}
                        </S.CardButtonAccess>
                      </S.CardFooter>
                    </S.CardContent>
                  </S.Card>
                ))}

              {!!channel?.type ||
                (channel.type != 'videoteca' && !user?.restrictChannels && (
                  <S.Card theme={theme}>
                  <Link
                    key={i}
                    to={{
                      pathname: '/' + props.params?.event + '/dashboard/' + channel.key,
                      key: String(new Date().getTime()),
                    }}
                  >
                    <S.CardImageContainer>
                      <S.CardImage alt="400x220" src={channel?.picture} />

                      <S.CardImageIcons>
                        {/* <Icon name="iconLock" /> */}
                        <span></span>
                        {/* <div>
                          <span className="card-channel-icon-live">NO AR</span>
                          <Icon name="iconEye" />
                        </div> */}
                      </S.CardImageIcons>
                    </S.CardImageContainer>
                  </Link>
                  <S.CardContent theme={theme}>
                    <S.CardInfo>
                      <S.CardSpeakers>
                        {channel?.speakers.map((speakerImage: any) => (
                          speakerImage?.picture &&
                            <img alt="44x44" src={speakerImage?.picture} />
                        ))}
                      </S.CardSpeakers>
                      <S.CardInfoHeader>
                        <S.CardTitle theme={theme}>{channel?.name}</S.CardTitle>
                        <S.CardTitle theme={theme}>
                          {channel?.hour && `${channel?.hour}`}
                        </S.CardTitle>
                      </S.CardInfoHeader>
                      <S.CardInfoText theme={theme}>
                        {channel?.description_languages[i18n.language]}
                      </S.CardInfoText>
                    </S.CardInfo>
                    <S.CardFooter theme={theme}>
                      <S.ContainerIcons>
                      {asEnumerable(eventContext.event?.resources || []).Any(
                        (r: any) => r.key == 'online',
                      ) && (user?.isModerator || user?.isAttendance) &&
                        <button className="card-channels-item-icon icon-people">
                          <Icon name="iconPeople" />
                          <UsersOnlineCount
                            eventId={props.eventId}
                            channelId={channel.id}
                            channelName={channel.name}
                          />
                      </button>
                      }
                        <button
                          className="card-channels-item-icon icon-heart"
                          onClick={() => like(channel)}
                        >
                          <Icon name="iconHeart" />
                          <span>{channel?.likes || 0}</span>
                        </button>
                      </S.ContainerIcons>

                      <S.CardButtonAccess href={`/${props.params?.event}/dashboard/${channel.key}`}
                      >
                        {t("Access")}
                      </S.CardButtonAccess>
                    </S.CardFooter>
                  </S.CardContent>
                </S.Card>
                ))}
            </React.Fragment>
          ) : (
            <React.Fragment key={i}>
              {!!user?.restrictChannels &&
                Object.keys(user?.restrictChannels)?.findIndex(
                  (channelKey) =>
                    channelKey?.trim() === channel?.key?.trim(),
                ) >= 0 && (
                  <S.Card theme={theme}>
                  <Link
                    key={i}
                    to={{
                      pathname: '/' + props.params?.event + '/dashboard/' + channel.key,
                      key: String(new Date().getTime()),
                    }}
                  >
                    <S.CardImageContainer>
                      <S.CardImage alt="400x220" src={channel?.picture} />

                      <S.CardImageIcons>
                        {/* <Icon name="iconLock" /> */}
                        <span></span>
                        {/* <div>
                          <span className="card-channel-icon-live">NO AR</span>
                          <Icon name="iconEye" />
                        </div> */}
                      </S.CardImageIcons>
                    </S.CardImageContainer>
                  </Link>
                  <S.CardContent theme={theme}>
                    <S.CardInfo>
                      <S.CardSpeakers>
                        {channel?.speakers.map((speakerImage: any) => (
                          speakerImage?.picture &&
                            <img alt="44x44" src={speakerImage?.picture} />
                        ))}
                      </S.CardSpeakers>
                      <S.CardInfoHeader>
                        <S.CardTitle theme={theme}>{channel?.name}</S.CardTitle>
                        <S.CardTitle theme={theme}>
                          {channel?.hour && `${channel?.hour}`}
                        </S.CardTitle>
                      </S.CardInfoHeader>
                      <S.CardInfoText theme={theme}>
                        {channel?.description_languages[i18n.language]}
                      </S.CardInfoText>
                    </S.CardInfo>
                    <S.CardFooter theme={theme}>
                      <S.ContainerIcons>
                      {asEnumerable(eventContext.event?.resources || []).Any(
                        (r: any) => r.key == 'online',
                      ) && (user?.isModerator || user?.isAttendance)  &&
                        <button className="card-channels-item-icon icon-people">
                          <Icon name="iconPeople" />
                          {/* <UsersOnlineCount
                            eventId={props.eventId}
                            channelId={channel.id}
                            channelName={channel.name}
                          /> */}
                          <UsersCountComponent/>
                      </button>
                      }
                        <button
                          className="card-channels-item-icon icon-heart"
                          onClick={() => like(channel)}
                        >
                          <Icon name="iconHeart" />
                          <span>{channel?.likes || 0}</span>
                        </button>
                      </S.ContainerIcons>

                      <S.CardButtonAccess href={`/${props.params?.event}/dashboard/${channel.key}`}
                      >
                        {t("Access")}
                      </S.CardButtonAccess>
                    </S.CardFooter>
                  </S.CardContent>
                </S.Card>
              )}
              {channel?.key != 'stand3d' && !user?.restrictChannels && (
                <S.Card theme={theme}>
                <Link
                  key={i}
                  to={{
                    pathname: '/' + props.params?.event + '/dashboard/' + channel.key,
                    key: String(new Date().getTime()),
                  }}
                >
                  <S.CardImageContainer>
                    <S.CardImage alt="400x220" src={channel?.picture} />

                    <S.CardImageIcons>
                      {/* <Icon name="iconLock" /> */}
                      <span></span>
                      {/* <div>
                        <span className="card-channel-icon-live">NO AR</span>
                        <Icon name="iconEye" />
                      </div> */}
                    </S.CardImageIcons>
                  </S.CardImageContainer>
                </Link>
                <S.CardContent theme={theme}>
                  <S.CardInfo>
                    <S.CardSpeakers>
                      {channel?.speakers.map((speakerImage: any) => (
                          speakerImage?.picture &&
                          <img alt="44x44" src={speakerImage?.picture} />
                      ))}
                    </S.CardSpeakers>
                    <S.CardInfoHeader>
                      <S.CardTitle theme={theme}>{channel?.name}</S.CardTitle>
                      <S.CardTitle theme={theme}>
                        {channel?.hour && `${channel?.hour}`}
                      </S.CardTitle>
                    </S.CardInfoHeader>
                    <S.CardInfoText theme={theme}>
                      {channel?.description_languages[i18n.language]}
                    </S.CardInfoText>
                  </S.CardInfo>
                  <S.CardFooter theme={theme}>
                    <S.ContainerIcons>
                    {asEnumerable(eventContext.event?.resources || []).Any(
                      (r: any) => r.key == 'online',
                    ) && (user?.isModerator || user?.isAttendance)  &&
                      <button className="card-channels-item-icon icon-people">
                        <Icon name="iconPeople" />
                        <UsersCountComponent/>
                      </button>
                    }
                      <button
                        className="card-channels-item-icon icon-heart"
                        onClick={() => like(channel)}
                      >
                        <Icon name="iconHeart" />
                        <span>{channel?.likes || 0}</span>
                      </button>
                    </S.ContainerIcons>

                    <S.CardButtonAccess href={`/${props.params?.event}/dashboard/${channel.key}`}
                    >
                      {t("Access")}
                    </S.CardButtonAccess>
                  </S.CardFooter>
                </S.CardContent>
              </S.Card>
              )}
            </React.Fragment>
          ),
        )}
        </S.Container>
      ): (
        <S.Container ref={ref} onMouseDown={onMouseDown}>
             {channels.map(
              (channel: any, i: number) =>
                (!props.params.channel ||
                  channel.id != eventContext.channel.id) && (
                  <React.Fragment key={i}>
                    {channel?.key != 'stand3d' &&
                      !!user?.restrictChannels &&
                      Object.keys(user?.restrictChannels)?.findIndex(
                        (channelKey) =>
                          channelKey?.trim() === channel?.key?.trim(),
                      ) >= 0 && (
                        <S.Card theme={theme}>
                        <Link
                          key={i}
                          to={{
                            pathname: '/' + props.params?.event + '/dashboard/' + channel.key,
                            key: String(new Date().getTime()),
                          }}
                        >
                          <S.CardImageContainer>
                            <S.CardImage alt="400x220" src={channel?.picture} />

                            <S.CardImageIcons>
                              {/* <Icon name="iconLock" /> */}
                              <span></span>
                              {/* <div>
                                <span className="card-channel-icon-live">NO AR</span>
                                <Icon name="iconEye" />
                              </div> */}
                            </S.CardImageIcons>
                          </S.CardImageContainer>
                        </Link>
                        <S.CardContent theme={theme}>
                          <S.CardInfo>
                            <S.CardSpeakers>
                              {channel?.speakers.map((speakerImage: any) => (
                                  speakerImage?.picture &&
                                  <img alt="44x44" src={speakerImage?.picture} />
                              ))}
                            </S.CardSpeakers>
                            <S.CardInfoHeader>
                              <S.CardTitle theme={theme}>{channel?.name}</S.CardTitle>
                              <S.CardTitle theme={theme}>
                                {channel?.hour && `${channel?.hour}`}
                              </S.CardTitle>
                            </S.CardInfoHeader>
                            <S.CardInfoText theme={theme}>
                              {channel?.description_languages[i18n.language]}
                            </S.CardInfoText>
                          </S.CardInfo>
                          <S.CardFooter theme={theme}>
                            <S.ContainerIcons>
                            {asEnumerable(eventContext.event?.resources || []).Any(
                              (r: any) => r.key == 'online',
                            ) && (user?.isModerator || user?.isAttendance)  &&
                              <button className="card-channels-item-icon icon-people">
                                <Icon name="iconPeople" />
                                <UsersCountComponent/>
                              </button>
                            }
                              <button
                                className="card-channels-item-icon icon-heart"
                                onClick={() => like(channel)}
                              >
                                <Icon name="iconHeart" />
                                <span>{channel?.likes || 0}</span>
                              </button>
                            </S.ContainerIcons>

                            <S.CardButtonAccess href={`/${props.params?.event}/dashboard/${channel.key}`}
                            >
                              {t("Access")}
                            </S.CardButtonAccess>
                          </S.CardFooter>
                        </S.CardContent>
                      </S.Card>
                    )}
          {channel?.key != 'stand3d' && !user?.restrictChannels && (
            <S.Card theme={theme}>
             <Link
               key={i}
               to={{
                 pathname: '/' + props.params?.event + '/dashboard/' + channel.key,
                 key: String(new Date().getTime()),
               }}
             >
               <S.CardImageContainer>
                 <S.CardImage alt="400x220" src={channel?.picture} />

                 <S.CardImageIcons>
                   {/* <Icon name="iconLock" /> */}
                   <span></span>
                   {/* <div>
                     <span className="card-channel-icon-live">NO AR</span>
                     <Icon name="iconEye" />
                   </div> */}
                 </S.CardImageIcons>
               </S.CardImageContainer>
             </Link>
             <S.CardContent theme={theme}>
               <S.CardInfo>
                 <S.CardSpeakers>
                   {channel?.speakers.map((speakerImage: any) => (
                       speakerImage?.picture &&
                       <img alt="44x44" src={speakerImage?.picture} />
                   ))}
                 </S.CardSpeakers>
                 <S.CardInfoHeader>
                   <S.CardTitle theme={theme}>{channel?.name}</S.CardTitle>
                   <S.CardTitle theme={theme}>
                     {channel?.hour && `${channel?.hour}`}
                   </S.CardTitle>
                 </S.CardInfoHeader>
                 <S.CardInfoText theme={theme}>
                   {channel?.description_languages[i18n.language]}
                 </S.CardInfoText>
               </S.CardInfo>
               <S.CardFooter theme={theme}>
                 <S.ContainerIcons>
                 {asEnumerable(eventContext.event?.resources || []).Any(
                   (r: any) => r.key == 'online',
                 ) && (user?.isModerator || user?.isAttendance)  &&
                   <button className="card-channels-item-icon icon-people">
                     <Icon name="iconPeople" />
                     <UsersCountComponent/>
                   </button>
                 }
                   <button
                     className="card-channels-item-icon icon-heart"
                     onClick={() => like(channel)}
                   >
                     <Icon name="iconHeart" />
                     <span>{channel?.likes || 0}</span>
                   </button>
                 </S.ContainerIcons>

                 <S.CardButtonAccess href={`/${props.params?.event}/dashboard/${channel.key}`}
                 >
                   {t("Access")}
                 </S.CardButtonAccess>
               </S.CardFooter>
             </S.CardContent>
           </S.Card>
          )}
                    {channel?.key === 'stand3d' &&
                      !!user?.restrictChannels &&
                      Object.keys(user?.restrictChannels)?.findIndex(
                        (channelKey) =>
                          channelKey?.trim() === channel?.key?.trim(),
                      ) >= 0 && (
                        <S.Card theme={theme}>
                        <Link
                          key={i}
                          to={{
                            pathname: '/' + props.params?.event + '/dashboard/' + channel.key,
                            key: String(new Date().getTime()),
                          }}
                        >
                          <S.CardImageContainer>
                            <S.CardImage alt="400x220" src={channel?.picture} />

                            <S.CardImageIcons>
                              {/* <Icon name="iconLock" /> */}
                              <span></span>
                              {/* <div>
                                <span className="card-channel-icon-live">NO AR</span>
                                <Icon name="iconEye" />
                              </div> */}
                            </S.CardImageIcons>
                          </S.CardImageContainer>
                        </Link>
                        <S.CardContent theme={theme}>
                          <S.CardInfo>
                            <S.CardSpeakers>
                              {channel?.speakers.map((speakerImage: any) => (
                                  speakerImage?.picture &&
                                  <img alt="44x44" src={speakerImage?.picture} />
                              ))}
                            </S.CardSpeakers>
                            <S.CardInfoHeader>
                              <S.CardTitle theme={theme}>{channel?.name}</S.CardTitle>
                              <S.CardTitle theme={theme}>
                                {channel?.hour && `${channel?.hour}`}
                              </S.CardTitle>
                            </S.CardInfoHeader>
                            <S.CardInfoText theme={theme}>
                              {channel?.description_languages[i18n.language]}
                            </S.CardInfoText>
                          </S.CardInfo>
                          <S.CardFooter theme={theme}>
                            <S.ContainerIcons>
                            {asEnumerable(eventContext.event?.resources || []).Any(
                              (r: any) => r.key == 'online',
                            ) && (user?.isModerator || user?.isAttendance)  &&
                              <button className="card-channels-item-icon icon-people">
                                <Icon name="iconPeople" />
                                <UsersCountComponent/>
                              </button>
                            }
                              <button
                                className="card-channels-item-icon icon-heart"
                                onClick={() => like(channel)}
                              >
                                <Icon name="iconHeart" />
                                <span>{channel?.likes || 0}</span>
                              </button>
                            </S.ContainerIcons>

                            <S.CardButtonAccess href={`/${props.params?.event}/dashboard/${channel.key}`}
                            >
                              {t("Access")}
                            </S.CardButtonAccess>
                          </S.CardFooter>
                        </S.CardContent>
                      </S.Card>
                      )}

            {channel?.key === 'stand3d' && !user?.restrictChannels && (
             <S.Card theme={theme}>
              <Link
                key={i}
                to={{
                  pathname: '/' + props.params?.event + '/dashboard/' + channel.key,
                  key: String(new Date().getTime()),
                }}
              >
               <S.CardImageContainer>
                 <S.CardImage alt="400x220" src={channel?.picture} />

                 <S.CardImageIcons>
                   {/* <Icon name="iconLock" /> */}
                   <span></span>
                   {/* <div>
                     <span className="card-channel-icon-live">NO AR</span>
                     <Icon name="iconEye" />
                   </div> */}
                 </S.CardImageIcons>
               </S.CardImageContainer>
             </Link>
             <S.CardContent theme={theme}>
               <S.CardInfo>
                 <S.CardSpeakers>
                   {channel?.speakers.map((speakerImage: any) => (
                       speakerImage?.picture &&
                       <img alt="44x44" src={speakerImage?.picture} />
                   ))}
                 </S.CardSpeakers>
                 <S.CardInfoHeader>
                   <S.CardTitle theme={theme}>{channel?.name}</S.CardTitle>
                   <S.CardTitle theme={theme}>
                     {channel?.hour && `${channel?.hour}`}
                   </S.CardTitle>
                 </S.CardInfoHeader>
                 <S.CardInfoText theme={theme}>
                   {channel?.description_languages[i18n.language]}
                 </S.CardInfoText>
               </S.CardInfo>
               <S.CardFooter theme={theme}>
                 <S.ContainerIcons>
                 {asEnumerable(eventContext.event?.resources || []).Any(
                   (r: any) => r.key == 'online',
                 ) && (user?.isModerator || user?.isAttendance)  &&
                   <button className="card-channels-item-icon icon-people">
                     <Icon name="iconPeople" />
                     <UsersCountComponent/>
                   </button>
                 }
                   <button
                     className="card-channels-item-icon icon-heart"
                     onClick={() => like(channel)}
                   >
                     <Icon name="iconHeart" />
                     <span>{channel?.likes || 0}</span>
                   </button>
                 </S.ContainerIcons>

                 <S.CardButtonAccess href={`/${props.params?.event}/dashboard/${channel.key}`}
                 >
                   {t("Access")}
                 </S.CardButtonAccess>
               </S.CardFooter>
             </S.CardContent>
           </S.Card>
          )}
         </React.Fragment>
        ),
       )}
      </S.Container>
     )}
    </>
  );
};
export default CardSlideChannel;
